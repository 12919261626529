import {categoriesData} from'src/utils/categoriesData';

export const categories = {
  pleadings: 'Pleadings',
  motions: 'Motions',
  discovery: 'Discovery'
};

export const getCategories = (p_categoryTitle) => {
  let l_categoriesData = [];
  var l_data = categoriesData.mainCategory;
  for (var index=0; index<l_data.length;index++)
  {
    if(l_data[index].description==p_categoryTitle)
    {
      for (var i=0; i<l_data[index].category.length;i++)
      {
        l_categoriesData.push(l_data[index].category[i].description)
      }
    }
  }
  return l_categoriesData;
};

export const getTask = (p_categoryTitle,parentTitle) => {
  
  let l_taskData = [];
  var l_data = categoriesData.mainCategory;
  for (var index=0; index<l_data.length;index++)
  {
    if(l_data[index].description==p_categoryTitle)
    {
      for (var i=0; i<l_data[index].category.length;i++)
      {
        if(l_data[index].category[i].description==parentTitle)
        {
          for (var j=0; j<l_data[index].category[i].task.length;j++)
          {
            l_taskData.push(l_data[index].category[i].task[j].description)
          }
        }
        
      }
    }
  }
  return l_taskData;
};
