import { Box, Button, Grid } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'src/logo.svg';
import HeaderUserBox from './Userbox';
import HeaderLinks from './HeaderLinks';


const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
  height: 100vh;
  padding-top: ${theme.spacing(3)};
  padding-bottom: ${theme.spacing(3)};
  //padding-left: ${theme.spacing(3)};
  display: flex;
  border-radius: 0;
  position: fixed;
  max-width: 250px;
  width: 100%;
`
);
const Sidebar = forwardRef((_, ref) => (
  <SidebarWrapper className='sidebar-wrapper' ref={ref}>
    <Box className='sidebardiv1'
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      justifyContent="space-between"
      gap={2}
    >
      <Box className='sidebardiv2' overflow="auto" display="flex" flexDirection="column" gap={2}>
        <Box display="flex" className='sidebardiv3' alignItems="center">
          <Link to="/secure">
            <LogoIcon />
          </Link>
        </Box>
        <Box className='sidelinks'
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          overflow="auto"
          height="100%"
        >
          <HeaderLinks />
        </Box>
      </Box>
      <Box className='userbox'>
        <HeaderUserBox />
      </Box>
    </Box>
  </SidebarWrapper>
));

export default Sidebar;
