import { createSelector } from '@reduxjs/toolkit';

export const selectUser = (state) => state.user;

export const selectUserLoadable = createSelector(
  selectUser,
  (state) => state.loadable
);
export const selectUserLoading = createSelector(
  selectUserLoadable,
  (state) => state.loading
);
export const selectUserUpdateLoadable = createSelector(
  selectUser,
  (state) => state.updateLoadable
);
export const selectUserUpdateSuccess = createSelector(
  selectUserUpdateLoadable,
  (loadable) => loadable.success
);
export const selectUserRateLoadable = createSelector(
  selectUser,
  (state) => state.userRateLoadable
);
export const selectUserRateLoading = createSelector(
  selectUserRateLoadable,
  (loadable) => loadable.loading
);
export const selectUserRateSuccess = createSelector(
  selectUserRateLoadable,
  (loadable) => loadable.success
);
