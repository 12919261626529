import { experimentalStyled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

function AuthBackground({ children }) {
  return <BackgroundContainer>{children}</BackgroundContainer>;
}

export default AuthBackground;

AuthBackground.propTypes = {
  children: PropTypes.any.isRequired
};

const BackgroundContainer = experimentalStyled(Box)(
  ({ theme }) => `
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-size: cover;
  background-color: ${theme.palette.secureBackground};
  // background: url("/static/images/guestbg.jpg") no-repeat center center fixed;
`
);
