import { Redirect, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

const ClientShareBudget = ({ children }) => {
  const { isAuthenticated, signInWithEmailAndPassword } = useAuth();
  const { teamId } = useParams();
  const { user,logout } = useAuth();

  if (!isAuthenticated) {
    signInWithEmailAndPassword('sharedBudget@vcs.com.pk', 'sharedBudget@vcs.com.pk')
        .then( x=> {
              dispatch(getCaseByIdAndIdentityAsync({ caseId, identity })),
              dispatch(getCaseCategoriesByCaseIdAsync(caseId))        
            })
        .catch((error) =>
          console.log(error.message)
          );
  }

  return <>{children}</>;
};

ClientShareBudget.propTypes = {
  children: PropTypes.node
};

export default ClientShareBudget;
