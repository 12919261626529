import { Avatar, Box, experimentalStyled, Button, Grid, Typography, } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/selectors/user';
import Overlay from 'react-bootstrap/Overlay';
import { Popover, OverlayTrigger, Carousel, } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle,faQuestion, faArrowAltCircleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import image_01 from 'src/assets/image/task_01.png';
import image_02 from 'src/assets/image/task_02.png';
import image_03 from 'src/assets/image/task_03.png';
import image_04 from 'src/assets/image/task_04.png';
import image_05 from 'src/assets/image/task_05.png';
import image_06 from 'src/assets/image/task_06.png';
import './style.css';
// import 'src/content/pages/Budgets-Style.css';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { updateUserAsync } from 'src/slices/user';
import { useDispatch } from 'react-redux';
import {
  selectUserRateSuccess
} from 'src/selectors/user';

const MyCollection = [
  {
    label: "1",
    imgPath: image_01,
    title: "Welcome to Feehive!",
    Text: "Clients trust their lawyer's legal skill and judgment. But the billable hour business model has a credibility problem. Now lawyers and clients can align on trustworthy hourly-fee budgets or alternative billing arrangements before an engagement begins."

  },
  {
    label: "2",
    imgPath: image_02,
    title: "Build",
    Text: "Use intuitive features and templates to build accurate, clear budgets for any legal matter. It’s easy to revise budgets for the same matter and re-use the best ones for new matters."
  },
  {
    label: "3",
    imgPath: image_03,
    title: "Collaborate",
    Text: "Invite your colleagues to collaborate on draft budgets.  Use their comments and insights to create more accurate fee forecasts. Save collective knowledge and never re-invent the wheel again."
  },
  {
    label: "4",
    imgPath: image_04,
    title: "Share",
    Text: "Once you’ve created a budget you can share it with your clients or counsel. You can invite them to comment on the budget or set permissions to read only.  You’ll be notified when you’ve received feedback.  "
  },
  {
    label: "5",
    imgPath: image_06,
    title: "Subscription Terms",
    Text: "The first thirty days are free. You’ll have full access to all Feehive features. No credit card required.Your free trial period will close on day 30. We’ll save anything you’ve created for 30 days in case you decide to subscribe within a month of the trial period.After the trial period ends, we’ll ask you for a credit card when you sign in.  We’ll bill you $35 per month. You can cancel at any time. If you cancel you will lose access to the budgets you’ve created and shared."
  },
];
function HeaderUserBox() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const CollectionSize = MyCollection.length;
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [buttonShow, setShowButton] = useState(false);
  const [show, setShow] = useState(false);
  const maxSteps = MyCollection.length;
  const isUserRateSuccess = useSelector(selectUserRateSuccess);

 

  useEffect(() => {
    const body = document.querySelector('#root');
    body.className = show ? 'rootDisable' : '';
  }, [show])

  useEffect(() => {
    if(user.showOnboarding===1)
    {
      setActiveStep(0), 
      setShow(!show), 
      setShowButton(false);
    }

  }, [user.showOnboarding===1])

  const goToNextPicture = () => {

    if (activeStep == CollectionSize - 1) {
      setShow(!show);
      setActiveStep(activeStep);
      if(user.showOnboarding===1)
      {
        dispatch(updateUserAsync({showOnboarding:0}))
      }
      
    }
    else {
      setActiveStep(activeStep + 1);
    }

  };
  const hidePopover = () => {
    setShow(!show);
  };

  const popover = (

    <Popover id="popover-basic" className='popover-basic'>
      <Popover.Body>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => { { setActiveStep(0), setShow(!show), setShowButton(false); if(user.showOnboarding===1){dispatch(updateUserAsync({showOnboarding:0}))};}}}>
          <Box sx={{ maxWidth: 800, maxHeight: 800, flexGrow: 1 }}>
            <Grid container item>
              <Grid className='popover-img padding-15' md={6} xs={6} gap={2}>
                <Box
                  component="img"
                  sx={{
                    height: 400,
                    display: 'block',
                    maxWidth: 800,
                    overflow: 'hidden',
                    width: '100%',
                  }}
                  src={MyCollection[activeStep].imgPath}
                  alt={MyCollection[activeStep].label}
                />
              </Grid>
              <Grid md={6} xs={6} className='padding-15'>
                <Typography className='popover-heading' sx={{ color: '#000', fontSize: "24px", fontWeight: "bold" }}>{MyCollection[activeStep].title}</Typography>
                <Typography className='popover-content' sx={{ color: '#000' }}>{MyCollection[activeStep].Text}</Typography>
              </Grid>

            </Grid>

            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                buttonShow == true ?
                  <Button
                    size="small"
                    onClick={hidePopover}
                    style={{background: '#F2B10E', borderRadius: 100, width: "36px", height: "36px", boxShadow: 'rgba(194, 133, 133, 0.2)' }}
                  >
                    finish
                  </Button>
                  :
                  <Button className='arrowbtns'
                    size="large"
                    onClick={goToNextPicture}
                    style={{ background: '#F2B10E', borderRadius: 900, width: "36px", height: "36px", boxShadow: 'rgba(194, 133, 133, 0.2)' }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ float: "left", color: "#fff", }}
                      size='lg'
                    />
                  </Button>
              }
            />
          </Box>
        </ClickAwayListener>
      </Popover.Body>
    </Popover>

  );
  return (
    <UserBox className='userbox1'>

      <Avatar style={{ gap: 10,cursor:'pointer' }} src={user.avatar || 'https://via.placeholder.com/150'} onClick={() => history.push('/secure/profile')} />
      <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden',cursor:'pointer',fontFamily:"FoundersGrotesk-Regular !important" }} onClick={() => history.push('/secure/profile')}>
        {user.firstName} {user.lastName}
      </Box>

      <OverlayTrigger trigger="click" placement="top" overlay={popover} show={show} rootClose>
        <Button onClick={() => { setActiveStep(0), setShow(!show), setShowButton(false); }} style={{ paddingLeft: 0, minWidth: 0, width: "20%", justifyContent: "flex-end", alignItems: "flex-end" }}>
          <FontAwesomeIcon
            icon={faQuestion}
            style={{ float: "left", color: "#929292", }}
            size='lg'
          />
        </Button>
      </OverlayTrigger>

    </UserBox>
  );
}

export default HeaderUserBox;

const UserBox = experimentalStyled(Box)(
  ({ theme }) => `
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  text-decoration: none;
  background: ${theme.palette.white};
  padding: ${theme.spacing(2)};
  line-height: ${theme.spacing(2)};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  font-weight: bold;
  gap: ${theme.spacing(1)};
  color: #000;
  cursor: 'pointer',
  font-family: 'FoundersGrotesk-Regular !important',
`
);
