

export const categoriesData ={
  "mainCategory": [
    {
      "mcid": 0,
      "description": "Litigation",
      "category": [
        {
          "cid": 0,
          "description": "Case Assessment, Development and Administration",
          "task": [
            {
              "tid": 0,
              "description": "Fact Investigation/Development",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Analysis/Strategy",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Experts/Consultants",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Document/File Management",
              "subTask": []
            },
            {
              "tid": 4,
              "description": "Budgeting",
              "subTask": []
            },
            {
              "tid": 5,
              "description": "Settlement/Non-Binding ADR",
              "subTask": []
            },
            {
              "tid": 6,
              "description": "Other Case Assessment, Development and Administration",
              "subTask": []
            }
          ]
        },
        {
          "cid": 1,
          "description": "Pre-Trial Pleadings and Motions",
          "task": [
            {
              "tid": 0,
              "description": "Pleading",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Preliminary Injunctions/Provisional Remedies",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Court Mandated Conferences",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Dispositive Motions",
              "subTask": [
                {
                  "stid": 0,
                  "description": "Motion to Dismiss"
                },
                {
                  "stid": 1,
                  "description": "Motion for Summary Judgment"
                }
              ]
            },
            {
              "tid": 4,
              "description": "Other Written Motions and Submissions",
              "subTask": []
            },
            {
              "tid": 5,
              "description": "Class Action Certification and Notice",
              "subTask": []
            }
          ]
        },
        {
          "cid": 2,
          "description": "Discovery",
          "task": [
            {
              "tid": 0,
              "description": "Written Discovery",
              "subTask": [
                {
                  "stid": 0,
                  "description": "Draft Interrogatories"
                },
                {
                  "stid": 1,
                  "description": "Respond to Interrogatories"
                },
                {
                  "stid": 2,
                  "description": "Draft Document Demands"
                },
                {
                  "stid": 3,
                  "description": "Respond to Document Demands"
                },
                {
                  "stid": 4,
                  "description": "Subpoena to Third Parties"
                }
              ]
            },
            {
              "tid": 1,
              "description": "Document Production",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Depositions",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Expert Discovery",
              "subTask": []
            },
            {
              "tid": 4,
              "description": "Discovery Motions",
              "subTask": []
            },
            {
              "tid": 5,
              "description": "Other Discovery",
              "subTask": []
            }
          ]
        },
        {
          "cid": 3,
          "description": "Trial Preparation and Trial",
          "task": [
            {
              "tid": 0,
              "description": "Fact Witnesses",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Expert Witnesses",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Written Motions and Submissions",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Other Trial Preparation and Support",
              "subTask": []
            },
            {
              "tid": 4,
              "description": "Trial and Hearing Attendance",
              "subTask": []
            },
            {
              "tid": 5,
              "description": "Post-Trial Motions and Submissions",
              "subTask": []
            },
            {
              "tid": 6,
              "description": "Enforcement",
              "subTask": []
            }
          ]
        },
        {
          "cid": 4,
          "description": "Appeal",
          "task": [
            {
              "tid": 0,
              "description": "Appellate Motions and Submissions",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Appellate Briefs",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Oral Argument",
              "subTask": []
            }
          ]
        },
        {
          "cid": 5,
          "description": "Expenses",
          "task": [
            {
              "tid": 0,
              "description": "Out-of-town travel",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Court fees",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Subpoena fees",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Witness fees",
              "subTask": []
            },
            {
              "tid": 4,
              "description": "Deposition transcripts",
              "subTask": []
            },
            {
              "tid": 5,
              "description": "Trial transcripts",
              "subTask": []
            },
            {
              "tid": 6,
              "description": "Trial exhibits",
              "subTask": []
            },
            {
              "tid": 7,
              "description": "Litigation support vendors",
              "subTask": []
            },
            {
              "tid": 8,
              "description": "Experts",
              "subTask": []
            },
            {
              "tid": 9,
              "description": "Arbitrators/mediators",
              "subTask": []
            },
            {
              "tid": 10,
              "description": "Local counsel",
              "subTask": []
            },
            {
              "tid": 11,
              "description": "Other professionals",
              "subTask": []
            },
            {
              "tid": 12,
              "description": "Other",
              "subTask": []
            }
          ]
        }
      ]
    },
    {
      "mcid": 1,
      "description": "Transaction",
      "category": [
        {
          "cid": 0,
          "description": "Preliminary Matters",
          "task": [
            {
              "tid": 0,
              "description": "Letter of Intent",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Confidentiality Agreements",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Drafting, negotiating and amending preliminary stage agreements",
              "subTask": [
                {
                  "stid": 0,
                  "description": "Establishing  bidding and auction procedures"
                },
                {
                  "stid": 1,
                  "description": "Service Provider Agreements (e.g., accountants, environmental consultants, investment bankers, etc.)"
                }
              ]
            },
            {
              "tid": 3,
              "description": "Preliminary Analyses",
              "subTask": []
            }
          ]
        },
        {
          "cid": 1,
          "description": "Purchase / Merger Agreement",
          "task": [
            {
              "tid": 0,
              "description": "Drafting, Negotiating and Amending Purchase / Merger Agreement",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Consultation with Experts on Terms and Provisions",
              "subTask": []
            }
          ]
        },
        {
          "cid": 2,
          "description": "Due Diligence and Disclosure Schedules",
          "task": [
            {
              "tid": 0,
              "description": "Document Review",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Prepare Due Diligence Material",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Establish Data Room",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Negotiating Due Diligence Reliance Ketters",
              "subTask": []
            },
            {
              "tid": 4,
              "description": "Conduct Due Diligence Searches",
              "subTask": []
            },
            {
              "tid": 5,
              "description": "Draft, Review and Negotiate Disclosure Schedules",
              "subTask": []
            }
          ]
        },
        {
          "cid": 3,
          "description": "Ancillary Documents",
          "task": [
            {
              "tid": 0,
              "description": "Buying/Selling parties Agreements",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Employment Agreements",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Non-solicitation and Non-competition Agreements",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Transition services agreements",
              "subTask": []
            },
            {
              "tid": 4,
              "description": "Supply agreements",
              "subTask": []
            },
            {
              "tid": 5,
              "description": "Escrow agreements",
              "subTask": []
            },
            {
              "tid": 6,
              "description": "Assignments",
              "subTask": []
            },
            {
              "tid": 7,
              "description": "Inventorying required consents and procuring third-party signatures",
              "subTask": []
            }
          ]
        },
        {
          "cid": 4,
          "description": "Financing",
          "task": [
            {
              "tid": 0,
              "description": "Drafting, Negotiating and Amending Acquisition Financing Agreements",
              "subTask": []
            }
          ]
        },
        {
          "cid": 5,
          "description": "Regulatory and Specialty Matters (non-attorney expenses)",
          "task": [
            {
              "tid": 0,
              "description": "Data Security and Privacy",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Employment and Benefits",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Executive Compensation",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Environmental",
              "subTask": []
            },
            {
              "tid": 4,
              "description": "Intellectual Property",
              "subTask": []
            },
            {
              "tid": 5,
              "description": "Real Property",
              "subTask": []
            },
            {
              "tid": 6,
              "description": "Securities Regulatory Matters",
              "subTask": []
            },
            {
              "tid": 7,
              "description": "Tax",
              "subTask": []
            }
          ]
        },
        {
          "cid": 6,
          "description": "Shareholder/Board Matters",
          "task": [
            {
              "tid": 0,
              "description": "Board Presentations",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Drafting minutes and resolutions",
              "subTask": []
            },
            {
              "tid": 2,
              "description": "Review of fairness opinions",
              "subTask": []
            },
            {
              "tid": 3,
              "description": "Advising the board regarding fiduciary duties/shareholder meetings",
              "subTask": []
            }
          ]
        },
        {
          "cid": 7,
          "description": "Closing Matters",
          "task": [
            {
              "tid": 0,
              "description": "Drafting and negotiating closing documents",
              "subTask": []
            },
            {
              "tid": 1,
              "description": "Attendance at Closing",
              "subTask": []
            }
          ]
        },
        {
          "cid": 8,
          "description": "Post-Closing Requirements, Disputes and Adjustments",
          "task": [
            {
              "tid": 0,
              "description": "Drafting and negotiating closing documentsNegotiation of ost-closing working capital, purchase price adjustments, earn-out and other adjustments, negotiating and litigating indemnification",
              "subTask": []
            }
          ]
        },
        {
          "cid": 9,
          "description": "Deal Management",
          "task": []
        }
      ]
    }
  ]
}