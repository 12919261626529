import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@material-ui/core';
import SuspenseLoader from 'src/components/SuspenseLoader';
import './style.css'

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

function OverlayLoader(props) {
 
    var l_isTeamLoading =props.visible; 
    var l_loadingText = props.loadingText;
  return (
    <DarkBackground disappear={l_isTeamLoading}>
    <LoadingOverlay
      active={true}
      //spinner={<SuspenseLoader />}
      spinner={true}
      text={l_loadingText}
      className="_loading_overlay_content"
    >
    </LoadingOverlay>
  </DarkBackground>
  );
}

export default OverlayLoader;