import firebase from 'src/utils/firebase';
import getStripe from "./initializeStripe";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
//const stripe = require('stripe')("pk_test_51K7MpDJRUmGJhIP2K8mzJZoc2axGKC9fOp1kVE1TeenUTBWzr6LEEUBxUN7VM14pgSXYOgUH5waVNNw4sKCih6uP00DBC4wUv6");
import { getUserByUserId, registerCustomer, updateUserById,getUserByEmail,deleteUser,deleteUserFromTeamByUserID,getUserCasePermissions,updateUserFromTeamByUserID,
  getTeamMemberByUserId,updateUserBySigninWithTeam,swapuserID,updateUserIDTeamMembers } from 'src/network';
  import {
    setSubscriptionDetail
  } from 'src/slices/teams';

export async function createCheckoutSession(data) {

  const stripe = await getStripe();
  const createStripeCheckout = firebase.functions().httpsCallable('createStripeCheckout');
  // totalmember = totalmember? totalmember : 0;
  createStripeCheckout(data).then((result) => {
    const l_sessionId = result.data.id;
    stripe.redirectToCheckout({ sessionId: l_sessionId });
  }).catch((error) => {
    console.log(error);
  });
}

export async function createCustomerPortal(CUSTOMER_ID) {
  const createCustomerPortal = firebase.functions().httpsCallable('createCustomerPortal');
  createCustomerPortal(CUSTOMER_ID).then((result) => {
    const l_url = result.data.id;

    window.open(l_url,"_self");
  }).catch((error) => {
    console.log(error);
  });
}

export async function createCustomer(p_data) {
  const createCustomer = firebase.functions().httpsCallable('createCustomer');
  createCustomer(p_data).then((result) => {
    var email = { customer:result.data.id};
     createSubscriptions(email);
    
     var customer = result.data;

    registerCustomer(customer)

    return result.data;
  }).catch((error) => {
    return error;
  });
}

export async function createSubscriptions(p_data) {
  const createSubscriptions = firebase.functions().httpsCallable('createSubscriptions');
  createSubscriptions(p_data).then((result) => {
    
    // getSubscription(p_data);
    return result.data;
  }).catch((error) => {
    return error;
  });
}

export async function getSubscription(p_data) {
  const getSubscription = firebase.functions().httpsCallable('getSubscription');
  
 var l_Respose = await getSubscription(p_data).then((result) => {
    
    // dispatch(setSubscriptionDetail(result.data));
    
// if(result.data.length >0)
// {
//   return <Redirect to="src/content/pages/PaymentPlan" />;
// }

    return result.data;
  }).catch((error) => {
    return error;
  });

  return l_Respose;
}