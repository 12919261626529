import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiContainer-root" : {
      marginLeft: 0,
      maxWidth:'100%',
      ["@media (min-height:600)"]: { padding: 0, }
    }
  }
}));

export  default useStyles;