import axios from 'axios';

const defaultOptions = {
  baseURL: process.env.REACT_APP_FEEHIVE_API,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*'
  },
};

const feehiveAPI = axios.create({
  defaultOptions
});

feehiveAPI.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

export { feehiveAPI };
