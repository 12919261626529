import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Loadable from 'src/utils/loadable';
import {
  createCase,
  updateCase,
  getClientById,
  createTeamActivity,
  createCaseComment,
  getTasksByCaseId,
  createCaseActivity,
  getTeamCasesById,
  deleteCaseById,
  getTaskMessages,
  createCasePermission,
  getCasePermissions,
  getCaseById,
  getCommentsByCaseId,
  getActivitiesByCaseId,
  getAssignableUsers,
  createTaskMessage,
  createTask,
  createCaseCategory,
  getCaseCategoriesByCaseId,
  updateNotes,
  deleteSharedCaseById,
  getCaseByCaseId
} from 'src/network';
import { getCaseCategoriesByCaseIdAsync } from './tasks';
import useAuth from 'src/hooks/useAuth';


const initialState = {
  list: [],
  currentCase: {
    assignableUsers: []
  },
  currentTaskMessages: [],
  isEditing: false,
  isSharing: false,
  isNewCase:true,
  createdCaseId: '',
  showEditText:false,
  blendedRate:0,
  currentCaseLoadable: Loadable().createDefaultLoadable(),
  caseListLoadable: Loadable().createDefaultLoadable(),
  caseCreateLoadable: Loadable().createDefaultLoadable(),
  createCaseCommentLoadable: Loadable().createDefaultLoadable(),
  deleteCaseLoadable: Loadable().createDefaultLoadable(),
  taskMessagesLoadable: Loadable().createDefaultLoadable(),
  copyCaseLoadable: Loadable().createDefaultLoadable(),
  autoAuthenticating:Loadable().createDefaultLoadable()
};

export const getCaseTaskMessagesAsync = createAsyncThunk(
  'cases/Task/Get/Messages',
  async (taskId) => getTaskMessages(taskId)
);
export const sendCaseTaskMessagesAsync = createAsyncThunk(
  'cases/Task/Send/Messages',
  async ({ taskId, message }, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    const currentCase = thunkAPI.getState().cases.currentCase;
    await createTaskMessage({ taskId, message, userId: currentUser.id });
    thunkAPI.dispatch(getCaseTaskMessagesAsync(taskId));
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(currentCase.case_id));
  }
);

export const getCasesListAsync = createAsyncThunk(
  'cases/List',
  async ({ status = 'all' }, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    const result = await getTeamCasesById({ userId: currentUser.id });
    const clients = await Promise.all(
      result.map(({ client_id }) => getClientById(client_id))
    );
    clients.forEach((client, idx) => (result[idx].client = client));
    const tasks = await Promise.all(
      result.map(({ case_id }) => getTasksByCaseId(case_id))
    );
    tasks.forEach((task, idx) => (result[idx].tasks = task));
    return result;
  }
);

export const createCaseCommentAsync = createAsyncThunk(
  'cases/messages/Create',
  async (message, thunkAPI) => {
    const user = thunkAPI.getState().user;
    const userId = user?.id;
    const caseId = thunkAPI.getState().cases.currentCase?.case_id;
    if (!userId) return;
    await Promise.all([
      createCaseComment({
        message,
        userId,
        case_id: caseId
      }),
      createCaseActivity({
        message: `${user.firstName} ${user.lastName} commented`,
        caseId,
        userId
      })
    ]);
  }
);

export const createAndCopyCaseAsync = createAsyncThunk(
  'cases/CreateAndCopy',
  async (caseId, thunkAPI) => {
    const state = thunkAPI.getState();
    const currentTeam = state.teams.team;
    const currentUser = state.user;
    const newCaseId = await createCase({
      teamId: currentTeam.team_id,
      clientId: '',
      status: '',
      title: '',
      fake_case_id: '',
      rate:0
    });
    await createCasePermission({
      case_id: newCaseId,
      permissions: 'admin',
      identity: currentUser.id,
      shared_mode:0,
      show_task_price:"0",
      shared_by:""
    });
    thunkAPI.dispatch(
      copyCaseAsync({
        currentCaseId: newCaseId,
        copyCaseId: caseId
      })
    );
  }
);

export const copyCaseAsync = createAsyncThunk(
  'cases/Copy',
  async ({ copyCaseId, currentCaseId }) => {
    const { case_id, ...rest } = await getCaseById(copyCaseId);
    const [copyTasks, copyCategories] = await Promise.all([
      getTasksByCaseId(case_id),
      getCaseCategoriesByCaseId(case_id)
    ]);
    const categoryTitle = copyCategories.length
      ? copyCategories[0].category?.title
      : '';
    const newCategoryId = await createCaseCategory({
      case_id: currentCaseId,
      title: categoryTitle
    });

    async function bfs() {
      const queue = [...copyTasks];
      while (queue.length) {
        const { task, tasks } = queue.shift();
        const taskId = await createTask({
          categoryId: newCategoryId,
          caseId: currentCaseId,
          clientId: task.client_id,
          parentTaskId: task.parent_task_id,
          assignedUser: task.assignedUser,
          hours: task.hours,
          rate: Number(task.rate).toFixed(2),
          title: task.title,
          task_sequence_no:task.task_sequence_no,
          category_sequence_no:task.category_sequence_no
        });
        if (task && tasks.length) {
          tasks.forEach((primary) => {
            const obj = { ...primary };
            obj.task.parent_task_id = taskId;
            queue.push(obj);
          });
        }
      }
    }

    await bfs();
    return await updateCase({ caseId: currentCaseId, ...rest });
  }
);

export const createCaseAsync = createAsyncThunk(
  'cases/Create',
  async ({ teamId, ...values }, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    const [caseId] = await Promise.all([
      createCase({
        teamId,
        userId: currentUser.id,
        ...values
      }),
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} created a budget`,
        teamId
      })
    ]);
    await createCasePermission({
      case_id: caseId,
      permissions: 'admin',
      identity: currentUser.id,
      shared_mode:0,
      show_task_price:"0",
      shared_by:""
    });
    return caseId;
  }
);

export const getCaseByIdAsync = createAsyncThunk(
  'case/Get',
  async (caseId, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    const permission = await getCasePermissions(currentUser.id, caseId);
    if (!permission?.case_id) return;
    const currentCase = await getCaseById(caseId);
    const [client, tasks, comments, activities, assignableUsers] =
      await Promise.all([
        getClientById(currentCase.client_id),
        getTasksByCaseId(caseId),
        getCommentsByCaseId(caseId),
        getActivitiesByCaseId(caseId),
        getAssignableUsers(caseId)
      ]);
    return {
      ...currentCase,
      client,
      tasks,
      comments,
      activities,
      permission,
      assignableUsers
    };
  }
);

export const getCaseByCaseIdAsync = createAsyncThunk(
  'case/Get',
  async (caseId, thunkAPI) => {
    const result = await getCaseByCaseId(caseId);
    
    return result
  }
);

export const autoSignInWithEmailAndPassword= createAsyncThunk(
  'case/GetAutoAuth',
  async ({ email, password }) => await new Promise((resolve, reject) => {    
    const { isAuthenticated,signInWithEmailAndPassword } = useAuth();    
     signInWithEmailAndPassword('sharedBudget@vcs.com.pk', 'sharedbudget@vcs.com.pk')
      .then((result) => {       
        return result;
      })
      .catch((res)=>{
        console.log(res);
      });

  })
  );



export const getCaseByIdAndIdentityAsync = createAsyncThunk(
  'case/GetWithIdentity',
  async ({ caseId, identity }) => {
    const permission = await getCasePermissions(identity, caseId);
    if (!permission?.case_id) return;
    // const data = await getCaseById(caseId);
    // const assignableUsers = await getAssignableUsers(caseId)
    const [data, assignableUsers] =
      await Promise.all([
        getCaseById(caseId),
        getAssignableUsers(caseId)
      ]);
    return {
      ...data,
      permission,
      assignableUsers
    };
  }
);

export const updateCaseByIdAsync = createAsyncThunk(
  'case/Update',
  async ({ caseId, ...values }, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    const clientId = thunkAPI.getState().cases.currentCase?.client_id;
    await Promise.all([
      await updateCase({ caseId, ...values }),
      createCaseActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} updated the case`,
        caseId,
        userId: currentUser.id,
        clientId
      })
    ]);
    thunkAPI.dispatch(getCaseByIdAsync(caseId));
    thunkAPI.dispatch(setCaseEditing(false));
  }
);

export const updateCaseNotes = createAsyncThunk(
  'case/Update',
  async ({ caseId, ...values }, thunkAPI) => {
    await updateNotes({ caseId, ...values });
    thunkAPI.dispatch(setCaseEditing(false));
    thunkAPI.dispatch(getCaseByIdAsync(caseId));
  }
);


export const deleteCaseAsync = createAsyncThunk('case/Delete', async (caseId) =>
  await deleteCaseById(caseId)
);

export const deleteSharedCaseAsync = createAsyncThunk('case/Delete', 
async ({caseId,identity}) =>
  await deleteSharedCaseById(caseId,identity)
);

const slice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    clearList(state) {
      state.list = [];
    },
    clearTaskMessages(state) {
      state.currentTaskMessages = [];
    },
    sortByCases(state, action) {
      state.list = action.payload;
    },
    setCaseEditing(state, action) {
      state.isEditing = action.payload;
    },
    setCaseSharing(state, action) {
      state.isSharing = action.payload;
    },
    setNewCase(state, action) {
      state.isNewCase = action.payload;
    },
    setshowEditText(state, action) {
      state.showEditText = action.payload;
    },
    setblendedRate(state, action) {
      state.blendedRate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCasesListAsync.pending, (state) => {
        state.caseListLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getCasesListAsync.fulfilled, (state, action) => {
        state.caseListLoadable = Loadable().createSuccessLoadable();
        state.list = action.payload;
      })
      .addCase(createCaseAsync.pending, (state) => {
        state.caseCreateLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(createCaseAsync.fulfilled, (state, action) => {
        state.caseCreateLoadable = Loadable().createSuccessLoadable();
        state.createdCaseId = action.payload;
      })
      .addCase(getCaseByIdAsync.pending, (state) => {
        state.currentCaseLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getCaseByIdAsync.fulfilled, (state, action) => {        
        state.currentCaseLoadable = Loadable().createSuccessLoadable();
        state.currentCase = action.payload;
      })
      .addCase(getCaseByIdAsync.rejected, (state) => {
        state.currentCaseLoadable =
          Loadable().createErrorLoadable('Failed to get case');
      })
      .addCase(getCaseByIdAndIdentityAsync.pending, (state) => {
        state.currentCaseLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getCaseByIdAndIdentityAsync.fulfilled, (state, action) => {
        state.currentCaseLoadable = Loadable().createSuccessLoadable();
        state.currentCase = action.payload;
      })
      .addCase(getCaseByIdAndIdentityAsync.rejected, (state) => {
        state.currentCaseLoadable =
          Loadable().createErrorLoadable('Failed to get case');
      })
      .addCase(createCaseCommentAsync.pending, (state) => {
        state.createCaseCommentLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(createCaseCommentAsync.fulfilled, (state, action) => {
        state.createCaseCommentLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(deleteCaseAsync.pending, (state) => {
        state.deleteCaseLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(deleteCaseAsync.fulfilled, (state, action) => {
        state.deleteCaseLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(getCaseTaskMessagesAsync.pending, (state) => {
        state.taskMessagesLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getCaseTaskMessagesAsync.fulfilled, (state, action) => {
        state.taskMessagesLoadable = Loadable().createSuccessLoadable();
        state.currentTaskMessages = action.payload;
      })
      .addCase(copyCaseAsync.pending, (state) => {
        state.copyCaseLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(copyCaseAsync.fulfilled, (state, action) => {
        state.copyCaseLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(autoSignInWithEmailAndPassword.pending, (state, action) => {
        console.log(action);
        //state.autoAuthenticating = Loadable().createLoadingLoadable();
      })
      .addCase(autoSignInWithEmailAndPassword.fulfilled, (state, action) => {
        console.log(action);
        state.autoAuthenticating = Loadable().createSuccessLoadable();
      })
      .addCase(autoSignInWithEmailAndPassword.rejected, (state, action) => {
        console.log(action);
        state.currentCaseLoadable =Loadable().createErrorLoadable(state);
      });
      
  }
});

export const { reducer } = slice;
export const {
  setCaseEditing,
  setCaseSharing,
  sortByCases,
  clearList,
  clearTaskMessages,
  setNewCase,
  setshowEditText,
  setblendedRate
} = slice.actions;

export default slice;
