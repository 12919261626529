import { createSelector } from '@reduxjs/toolkit';
import { getPermissionLevelForTeamMembers } from 'src/utils/teamMembers';
import { createClientHeaderAsync } from '../slices/teams';

export const selectTeams = (state) => state.teams;

export const selectAllTeams = createSelector(
  selectTeams,
  (teams) => teams.list
);

export const selectTeam = createSelector(selectTeams, (teams) => teams.team);

export const selectTeamLoadable = createSelector(
  selectTeams,
  (teams) => teams.teamLoadable
);
export const selectTeamFullLoadable = createSelector(
  selectTeams,
  (teams) => teams.teamFullLoadable
);
export const selectTeamLoading = createSelector(
  selectTeams,
  (teams) => teams.teamLoadable.loading || teams.teamFullLoadable.loading
);
export const selectTeamCases = createSelector(
  selectTeams,
  (teams) => teams.teamFull?.cases || []
);
export const selectLeaveTeamLoadable = createSelector(
  selectTeams,
  (teams) => teams.leaveTeamLoadable
);
export const selectLeaveTeamLoading = createSelector(
  selectLeaveTeamLoadable,
  (loadable) => loadable.loading
);
export const selectTeamsLoadable = createSelector(
  selectTeams,
  (team) => team.listLoadable
);
export const selectTeamsLoading = createSelector(
  selectTeamsLoadable,
  (loadable) => loadable.loading
);
export const selectTeamCreateLoadable = createSelector(
  selectTeams,
  (teams) => teams.teamCreateLoadable
);
export const selectTeamCreateLoading = createSelector(
  selectTeamCreateLoadable,
  (loadable) => loadable.loading
);
export const selectTeamCreateSuccess = createSelector(
  selectTeamCreateLoadable,
  (loadable) => loadable.success
);
export const selectTeamCreateFailure = createSelector(
  selectTeamCreateLoadable,
  (loadable) => loadable.error
);
export const selectTeamJoinLoadable = createSelector(
  selectTeams,
  (teams) => teams.joinLoadable
);
export const selectTeamJoiningSuccess = createSelector(
  selectTeamJoinLoadable,
  (loadable) => loadable.success
);
export const selectTeamClients = createSelector(
  selectTeams,
  (teams) => teams.teamFull.clients
);
export const selectTeamClientsCreateLoadable = createSelector(
  selectTeams,
  (team) => team.teamClientCreateLoadable
);
export const selectTeamClientsCreateSuccess = createSelector(
  selectTeamClientsCreateLoadable,
  (loadable) => loadable.success
);
export const selectTeamAlerts = createSelector(
  selectTeams,
  (teams) => teams.teamFull.alerts
);
export const selectTeamMembers = createSelector(
  selectTeams,
  (teams) => teams.teamFull?.members || []
);
export const selectTeamPlan = createSelector(
  selectTeams,
  (teams) => teams.team?.plan || ''
);

export const selectCustomerID = createSelector(
  selectTeams,
  (teams) => teams?.customerID || ''
);
export const selectCustomerDetail = createSelector(
  selectTeams,
  (teams) => teams?.customerDetail || []
);
// export const selectSubscriptionDetail = createSelector(
//   selectTeams,
//   (teams) => teams?.subscriptionDetail || []
// );
// export const setCaseOwner = createSelector(
//   selectTeams,
//   (teams) => teams?.caseOwner || []
// );
export const checkPlan = createSelector(
  selectTeams,
  (teams) => teams.team?.premium || 0
);
export const sendInvite = createSelector(
  selectTeams,
  (teams) => teams.sendInvite
);
export const inviteMessage = createSelector(
  selectTeams,
  (teams) => teams.inviteMessage
);
export const sharedBudegetUsername = createSelector(
  selectTeams,
  (teams) => teams.sharedBudegetUsername
);
export const selectTeamNameUpdateLoadable = createSelector(
  selectTeams,
  (teams) => teams.teamNameUpdateLoadable
);
export const selectTeamNameUpdateSuccess = createSelector(
  selectTeamNameUpdateLoadable,
  (loadable) => loadable.success
);
export const selectTeamPayment = createSelector(
  selectTeams,
  (teams) => teams.teamFull?.payment
);
export const selectAcceptMemberLoadable = createSelector(
  selectTeams,
  (teams) => teams.teamAcceptMemberLoadable
);
export const selectRemoveMemberLoadable = createSelector(
  selectTeams,
  (teams) => teams.teamRemoveMemberLoadable
);
export const selectAcceptMemberSuccess = createSelector(
  selectAcceptMemberLoadable,
  (loadable) => loadable.success
);
export const selectRemoveMemberSuccess = createSelector(
  selectRemoveMemberLoadable,
  (loadable) => loadable.success
);
export const selectCreatedClientId = createSelector(
  selectTeams,
  (teams) => teams.selectedClientId
);
export const selectCreatedClientIdLoadable = createSelector(
  selectTeams,
  (teams) => teams.createClientInCaseLoadable
);
