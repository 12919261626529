import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appWidth: 0,
  appHeight: 0
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppDimensions(state, action) {
      if (action.payload.width && action.payload.height) {
        state.appWidth = Number(action.payload.width);
        state.appHeight = Number(action.payload.height);
      }
    }
  }
});

export const { reducer } = slice;
export const { setAppDimensions } = slice.actions;

export default slice;
