import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Loadable from 'src/utils/loadable';
import teamMemberPermissions from 'src/utils/teamMembers';
import { Redirect, useParams,useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { getUserByUserId, registerUser, updateUserById,getUserByEmail,deleteUser,deleteUserFromTeamByUserID,getUserCasePermissions,updateUserFromTeamByUserID,
  getTeamMemberByUserId,updateUserBySigninWithTeam,swapuserID,updateUserIDTeamMembers,getCustomersDetail,getUsers,deleteCustomers,getCustomers } from 'src/network';
var moment = require('moment'); 
import { createCustomer,createSubscriptions, getCustomerObject } from "src/content/pages/stripe/createCheckoutSession";


const initialState = {
  id: '',
  avatar: '',
  firstName: '',
  lastName: '',
  expireDate:'',
  plan:'',
  premium: 1,
  showOnboarding:0,
  isGoogleUser:1,
  loadable: Loadable().createDefaultLoadable(),
  updateLoadable: Loadable().createDefaultLoadable(),
  userRateLoadable: Loadable().createDefaultLoadable()
};

export const getUserAsync = createAsyncThunk('user/Get', async (userObj, thunkAPI) => {
  let membership;
  let teamId = localStorage.getItem('teamId');

  var l_newUserData= await getUserByEmail(userObj.email);

  if(l_newUserData.user_id != undefined)
  {

    // membership = await getTeamMemberByUserId(l_newUserData.user_id);

    if(teamId)
    {
      if(userObj.email===l_newUserData.email && (teamId != null || teamId != ''))
      {
        var l_Result =  await updateUserFromTeamByUserID({userId:l_newUserData.user_id,teamId:teamId});
      }
    }
  }

  const userId = userObj.id || userObj.user_id;
  const user = await getUserByUserId(userId);
  const date = new Date();

  // var userData= await getUsers();

  // if(userData.length > 0)
  // {
  //   var customerData = await getCustomers();

  //   if(customerData.length > 0)
  //   {
  //     await deleteCustomers();
  //   }

  //   for(var i = 0;i < userData.length; i++)
  //   {
  //     var data = userData[i];

  //     if(data && data.email != undefined)
  //     {
  //       var email = data.email;

  //       data.name = data.first_name;
  
  //       // if(data.email == 'rehan@vcs.com.pk' || data.email == 'bscs133344@gmail.com')
  //       // {
  //         const customerData = await getCustomersDetail(data.email);
  
  //         if(customerData.length <= 0)
  //         {
  //           createCustomer(data);
  //         }
  //       // }
  //     }
  //   }
  // }

  if (user.first_name){
    return user;
  } 

  const [first, last] = userObj.name.split(' ');
  var isGoogleUser = 0;
  var password = '';
  var first_name = '';
  var last_name = '';
  var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}

  if(first && last)
  {
    var l_first= thunkAPI.getState().firstName;
    var l_lastName =thunkAPI.getState().lastName;
    
    isGoogleUser = 1;
  };

  var email = { email:userObj.email, name:first || userObj.email};
  
    if(l_newUserData.user_id === undefined || l_newUserData.user_id == '')
    {
      var l_UserDataParse  =  localStorage.getItem('User');
      var l_UserData = JSON.parse(l_UserDataParse);
     
      if(l_UserData)
      {
        password = Base64.encode(l_UserData.password);
        first_name = l_UserData.first_name;
        last_name = l_UserData.last_name;
      }

      await registerUser({
        first_name: first_name || first || 'Empty',
        last_name: last_name || last || '',
        user_id: userObj.id,
        email: userObj.email.toLowerCase(),
        avatar: userObj.avatar,
        hourlyRate: 0,
        registerDate:date,
        expireDate:'',
        plan:'',
        premium: 1,
        showOnboarding:0,
        isGoogleUser:isGoogleUser,
        activeUser:1,
        source:'user:getUserAsync',
        password:password
        })
        
        const customerData = await getCustomersDetail(userObj.email);

        if(customerData.length <= 0)
        {
          createCustomer(userObj);
        }

        localStorage.removeItem('User');
    }
    else
    {
      if(l_newUserData.activeUser===0)
      {
        var l_UserDataParse  =  localStorage.getItem('User');
        var l_UserData = JSON.parse(l_UserDataParse);
        
        if(l_UserData)
        {
          password = Base64.encode(l_UserData.password);
          first_name = l_UserData.first_name;
          last_name = l_UserData.last_name;
        }

        userObj.first_name = first_name || first || 'Empty';
        userObj.last_name = last_name || last || '';
        userObj.isGoogleUser = isGoogleUser;
        userObj.activeUser = 1;
        userObj.user_id = l_newUserData.user_id;
        userObj.password = password;

        await updateUserBySigninWithTeam({userObj});
        await updateUserAsync({userObj});

        localStorage.removeItem('User');
      }
    }
    
    if(l_newUserData.user_id != userObj.id  && teamId){
      var l_Call1 =  await swapuserID({email:userObj.email,newUserID:userObj.id})
      var l_Call2 = await updateUserIDTeamMembers({userId:l_newUserData.user_id,teamId:teamId,newUserID:userObj.id})
      // window.location.href = "/secure/budgets";
      // navigate("/secure/budgets");
    } 
   
    return  getUserByUserId(userId);
});

export const updateUserRateAsync = createAsyncThunk(
  'user/Update/Rate',
  ({ userId, hourlyRate }) => updateUserById(userId, { hourlyRate })
);

export const updateUserAsync = createAsyncThunk(
  'user/Update',
  async (values, thunkAPI) => {
    const user = thunkAPI.getState().user;
    return updateUserById(user.id, values);
  }
);

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFirstName(state, action) {
      state.firstName = action.payload;
    },
    setLastName(state, action) {
      state.lastName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state) => {
        state.loadable = Loadable().createLoadingLoadable();
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.loadable = Loadable().createSuccessLoadable();
        state.hourlyRate = action.payload.hourlyRate || 0;
        state.id = action.payload.user_id || '';
        state.firstName = action.payload.first_name || '';
        state.lastName = action.payload.last_name || '';
        state.avatar = action.payload.avatar || '';
        state.expireDate = action.payload.expireDate || '';
        state.plan = action.payload.plan || '';
        state.premium = action.payload.premium || 1;
        state.showOnboarding = action.payload.showOnboarding || 0;
        state.isGoogleUser = action.payload.isGoogleUser || 1;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.updateLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(updateUserAsync.fulfilled, (state) => {
        state.updateLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(updateUserRateAsync.pending, (state) => {
        state.userRateLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(updateUserRateAsync.fulfilled, (state) => {
        state.userRateLoadable = Loadable().createSuccessLoadable();
      });
  }
});

export const { reducer } = slice;
export const { setFirstName,setLastName } = slice.actions;

export default slice;
