import Logo from 'src/assets/image/logo.png';
import { format } from 'date-fns';

export function emailBody (p_title,p_budgetPermission,p_fake_case_id,p_date,p_company,p_statusColor,p_status,p_caseId,p_email,p_url,p_buttonText) {
    return `<html lang="en">
            <head>
                <meta name="viewport" content="width=device-width" />
                <title>Newsletter</title>
                <meta charset="utf-8">
                <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport">
            </head>
            <body style="margin:0; padding:0;">

            <div style="max-width:600px;margin:0 auto; font-family:Arial, Helvetica, sans-serif;">
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                        <td><img src="http://cdn.mcauto-images-production.sendgrid.net/f9af07cfef7c4b88/42bcfea7-85f6-4bf6-a3e7-4f3912cd7202/102x32.png" alt="" /></td>
                    </tr>
                    <tr>
                        <td>
                            <p style="font-size:18px; font-weight:bold; margin-bottom:0; margin-top:50px;">${p_title} shared a budget with you</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p style="font-size:15px; margin-bottom:50px;">${p_title} has invited you to ${p_budgetPermission} the following budget:</p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background: #FFFFFF; box-sizing: border-box; box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07); border-radius: 5px; padding-top: 25px; padding-bottom: 25px; padding-left: 35px; padding-right: 35px;">
                            <table cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td>
                                        <span style="font-size:14px; display:block; font-weight:bold;">Matter# ${p_fake_case_id}</span>
                                        <span style="color: #929292; font-size: 13px; display: block; margin-top:5px;">${format(new Date(p_date), 'MMMM do, yyyy')}</span>
                                    </td>
                                    <td>
                                        <span style="width:32px;height:32px;border-radius:50%; overflow:hidden; display:block;"><img src="https://via.placeholder.com/32" alt="Alternate Text" /></span>
                                    </td>
                                    <td>
                                        <span style="color: #929292; font-size: 13px; display: block; margin-top:5px;">${p_company}</span>
                                    </td>
                                    <td>
                                        <div style={{background:${p_statusColor}}} style="background-color: ${p_statusColor}!important; display: inline-block; border-radius: 90px; padding-top: 7px; padding-bottom: 7px; padding-left: 10px; padding-right: 10px;">
                                            <span style="background-color:#FFFFFF; border-radius:50%; width:12px; height:12px; display:inline-block; vertical-align:middle;"></span>
                                            <label style="color: #fff; font-size: 12px; margin: 0; padding-left: 5px; padding-right:5px; display: inline-block; vertical-align: middle;">${p_status}</label>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href=${p_url} style="margin-top: 50px; display: block; background: #F6D280; border: 1px solid #F6D280; box-sizing: border-box; border-radius: 5px; font-size: 18px;text-decoration:none; color: #404040; padding-left:20px;
                              padding-right:20px; padding-top:20px; padding-bottom:20px; text-align:center; font-weight:bold;">${p_buttonText}</a>
                        </td>
                    </tr>
                </table>
            </div>
        </body>
        </html>`
}