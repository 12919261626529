import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import SuspenseLoader from 'src/components/SuspenseLoader';
import firebase from 'src/utils/firebase';
import { experimentalStyled, Box } from '@material-ui/core';
import OverlayLoader from 'src/components/OverlayLoader';
const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  getUserByEmail: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = async (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(resolve)
        .catch(reject);
    });
  };

  const forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase.auth().sendPasswordResetEmail(email).then(resolve).catch(reject);
    });
  };

  const signInWithGoogle = () => {
    return new Promise((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then(resolve).catch(reject);
    });
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(resolve)
        .catch(reject);
    });
  };

  const getUserByEmail = async (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .getUserByEmail(email)
        .then(resolve)
        .catch(reject);
    });
  };

  const logout = () => {firebase.auth().signOut();
    localStorage.removeItem('teamId');
  }

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName || user.email
              }
            }
          });
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      }),
    [dispatch]
  );

  if (!state.isInitialised) {
    return (
      <FullCounter>
        <OverlayLoader visible={!state.isInitialised} loadingText={"Loading..."} />
      </FullCounter>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        getUserByEmail,
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        forgotPassword,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired
};

const FullCounter = experimentalStyled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh'
}));
