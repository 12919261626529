import { Redirect, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
const Guest = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { teamId } = useParams();
  const { user,logout } = useAuth();

  if (isAuthenticated) {

    if(user.email == 'sharedbudget@vcs.com.pk')
    {
      logout();

      return <Redirect to="src/content/pages/Auth/Login" />;
    }

    if (teamId) {
      return <Redirect to={`/secure/onboarding/teams/${teamId}`} />;
    }
    return <Redirect to="/secure/onboarding/teams/" />;
  }

  if(teamId)
  {
    localStorage.setItem('teamId',teamId);
  }
  else
  {
    localStorage.removeItem('teamId');
  }
  
  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node
};

export default Guest;
