import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import firebase from 'src/utils/firebase';
import teamMemberPermissions from 'src/utils/teamMembers';
import { categories } from './utils/events';

var moment = require('moment');

const firestore = firebase.firestore();


export const getTeamMemberByUserId = async (userId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('user_id', '==', userId)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });

  export const getTeamMemberByTeamID = async (userId,team_id) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('user_id', '==', userId)
      .where('team_id', '==', team_id)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });

export const checkIfTeamOwner = async (userId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teams')
      .where('owner', '==', userId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
        resolve({});
      })
      .catch(reject);
  });

  export const getTeamOwnerEmail = async ({teamOwner}) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('users')
      .where('user_id', '==', teamOwner)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
        resolve({});
      })
      .catch(reject);
  });

// export const getCustomersDetail = async ({ email }) => {
//   const permissions = await new Promise((resolve, reject) => {
//     firestore
//       .collection('customers')
//       .where('email', '==', email)
//       .get()
//       .then((snapshot) => {
//         const results = [];
//         snapshot.forEach((teamDoc) => {
//           resolve(teamDoc.data());
//         })
//         resolve({});
//       })
//         .catch(reject);
//     });
//   }

export const getCustomersDetail = async (email) =>
new Promise((resolve, reject) => {
  firestore
    .collection('customers')
    .where('email', '==', email)
    .get()
    .then((snapshot) => {
      const results = [];
      snapshot.forEach((doc) => {
        results.push(doc.data());
      });
      resolve(results);
  })
    .catch(reject);
});

export const getSubscriptionByDocID = async (userId) =>
  new Promise((resolve) => {
    firestore
      .collection('customers')
      .doc(userId)
      .collection('subscriptions')
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
      })
      .finally(() => {
        resolve({});
      });
  });



export const getTeamByTeamId = async (teamId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teams')
      .where('team_id', '==', teamId)
      .get()
      .then((teamSnapshot) => {
      teamSnapshot.forEach((teamDoc) => {
        resolve(teamDoc.data());
      })
      resolve({});
    })
      .catch(reject);
  });

export const getAssignableUsers = async (caseId) => {
  const permissions = await new Promise((resolve, reject) => {
    firestore
      .collection('casePermissions')
      .where('case_id', '==', caseId)
      .get()
      .then((teamSnapshot) => {
        const results = [];
        teamSnapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });
  return Promise.all(
    permissions.reduce((acc, permission) => {
      if (permission.identity.includes('@')) return acc;
      acc.push(getUserByUserId(permission.identity));
      return acc;
    }, [])
  );
};

export const getCasePermissions = async (identity, caseId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('casePermissions')
      .where('case_id', '==', caseId)
      .where('identity', '==', identity)
      .get()
      .then((teamSnapshot) => {
        teamSnapshot.forEach((teamDoc) => {
          resolve(teamDoc.data());
        });
        resolve({});
      })
      .catch(reject);
  });

export const getUserCasePermissions = async (identity, caseId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('casePermissions')
      .where('shared_mode', '==', caseId)
      .where('identity', '==', identity)
      .get()
      .then((teamSnapshot) => {
        teamSnapshot.forEach((teamDoc) => {
          resolve(teamDoc.data());
        });
        resolve({});
      })
      .catch(reject);
  });

export const getCasePermissionsByIdentity = async (identity) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('casePermissions')
      .where('identity', '==', identity)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });

export const getTeams = async () =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teams')
      .get()
      .then((snapshot) => {
        let result = [];
        snapshot.forEach((doc) => {
          result.push(doc.data());
        });
        // eslint-disable-next-line camelcase
        result = result.filter(({ team_name }) => team_name);
        resolve(result);
      })
      .catch(reject);
  });

  export const getUsers = async () =>
  new Promise((resolve, reject) => {
    firestore
      .collection('users')
      .get()
      .then((snapshot) => {
        let result = [];
        snapshot.forEach((doc) => {
          result.push(doc.data());
        });

        resolve(result);
      })
      .catch(reject);
  });

export const createTeam = async ({ teamName, userId }) => {
  const teamId = uuidv4();
  const date = new Date();
  await Promise.all([
    new Promise((resolve, reject) => {
      firestore
        .collection('teams')
        .add({
          team_name: teamName,
          owner: userId,
          team_id: teamId,
          plan: '',
          premium: 1,
          registerDate: date,
          expiredate: ""
        })
        .then(resolve)
        .catch(reject);
    }),
    addTeamMember({ teamId, userId, permissions: 'admin',requestPermissions:"" })
  ]);
};

const sendInviteEmail = ({teamId,userId}) => {
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('team_id', '==', teamId)
      .where('user_id', '==', userId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
      })
      .catch(reject);
  });
};

export const addTeamMember = async ({
  teamId,
  userId,
  permissions = teamMemberPermissions.user,
  requestPermissions 
}) => {
  await Promise.all([
    // sendInviteEmail(teamId,userId),
    new Promise((resolve, reject) => {
      firestore
        .collection('teamMembers')
        .add({
          team_id: teamId,
          user_id: userId,
          permissions,
          requestPermissions
        })
        .then(resolve)
        .catch(reject);
    })
  ]);
};

export const deleteCaseById = async (caseId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .where('case_id', '==', caseId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.ref.delete());
        });
      })
      .catch(reject);
  });

  
export const getCustomers = async (email) =>
new Promise((resolve, reject) => {
  firestore
    .collection('customers')
    .get()
    .then((snapshot) => {
      const results = [];
      snapshot.forEach((doc) => {
        results.push(doc.data());
      });
      resolve(results);
  })
    .catch(reject);
});

  export const deleteCustomers = async () =>
  new Promise((resolve, reject) => {
    firestore
      .collection('customers')
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.ref.delete());
        });
      })
      .catch(reject);
  });

export const deleteSharedCaseById = async (caseId, identity, values) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('casePermissions')
      .where('case_id', '==', caseId)
      .where('identity', '==', identity)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            resolve(doc.ref.delete())
          );
        });
      })
      .catch(reject);
  });

export const registerUser = async (user) =>
  new Promise((resolve, reject) => {
    firestore.collection('users').add(user).then(resolve).catch(reject);
  });

  export const registerCustomer = async (customer) =>
  new Promise((resolve, reject) => {
    firestore.collection('customers').add(customer).then(resolve).catch(reject);
  });

export const updateUserById = async (userId, values) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('users')
      .where('user_id', '==', userId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            doc.ref.set({
              ...doc.data(),
              ...values
            })
          );
        });
      })
      .catch(reject)
      .finally(() => {
        resolve({});
      })
      
  });

export const getUserByUserId = async (userId) =>
  new Promise((resolve) => {
    firestore
      .collection('users')
      .where('user_id', '==', userId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
      })
      .finally(() => {
        resolve({});
      });
  });

export const getUserByEmail = async (email) =>
  new Promise((resolve) => {
    firestore
      .collection('users')
      .where('email', '==', email)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
      })
      .finally(() => {
        resolve({});
      });
  });

export const updateTeamName = async ({ teamName, teamId }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teams')
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            doc.ref.set({
              ...doc.data(),
              team_name: teamName
            })
          );
        });
      })
      .catch(reject);
  });

export const updateCaseMembership = async ({ teamId, userId }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          resolve(
            doc.ref.set({
              ...doc.data()
            })
          );
        });
      })
      .catch(reject);
  });

export const updateCasesMembershipBatch = async ({ teamId, userIds }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          resolve(
            doc.ref.set({
              ...doc.data()
            })
          );
        });
      })
      .catch(reject);
  });

export const updateTeamMember = async ({ userId, teamId, ...rest }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('user_id', '==', userId)
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            doc.ref.set({
              ...doc.data(),
              ...rest
            })
          );
        });
      })
      .catch(reject);
  });

  export const updateTeamMemberPermissions = async ({ userId, teamId, ...rest }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('user_id', '==', userId)
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            doc.ref.set({
              ...doc.data(),
              ...rest
            })
          );
        });
      })
      .catch(reject);
  });

export const updateClient = async ({ clientId, ...rest }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamClients')
      .where('client_id', '==', clientId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            doc.ref.set({
              ...doc.data(),
              ...rest
            })
          );
        });
      })
      .catch(reject);
  });

export const updateTeamPlanById = async ({ plan, teamId }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teams')
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            doc.ref.set({
              ...doc.data(),
              plan
            })
          );
        });
      })
      .catch(reject);
  });

export const createClient = async ({ teamId, userId, ...rest }) => {
  const client_id = uuidv4();
  return new Promise((resolve, reject) => {
    firestore
      .collection('teamClients')
      .add({
        client_id,
        team_id: teamId,
        allowed_ids: [userId],
        ...rest
      })
      .then(() => {
        resolve(client_id);
      })
      .catch(reject);
  });
};

export const getClientById = async (clientId) => {
  if (!clientId) return {};
  return new Promise((resolve, reject) => {
    firestore
      .collection('teamClients')
      .where('client_id', '==', clientId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
        resolve({});
      })
      .catch(reject);
  });
};
export const getCasesByClientId = async (clientId) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .where('client_id', '==', clientId)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });
};

export const getCaseByCaseId = async (caseId) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .where('case_id', '==', caseId)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });
}
// export const getCases = async (caseID) => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection('teamCases')
//       .where('case_id', '==', caseID)
//       .get()
//       .then((snapshot) => {
//         const results = [];
//         snapshot.forEach((doc) => {
//           results.push(doc.data());
//         });
//         resolve(results);
//       })
//       .catch(reject);
//   });
// };

export const getTeamClientsByTeamId = async ({ teamId, userId }) => {
  let clients = await new Promise((resolve, reject) => {
    firestore
      .collection('teamClients')
      .where('team_id', '==', teamId)
      .where('allowed_ids', 'array-contains', userId)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });
  clients = clients.filter(({ client_id }) => client_id);
  // Get cases for each client
  const { clientsKeys, clientProms } = clients.reduce(
    (acc, cur) => {
      acc.clientsKeys[cur.client_id] = {
        ...cur,
        cases: []
      };
      acc.clientProms.push(getCasesByClientId(cur.client_id));
      return acc;
    },
    { clientsKeys: Object.create(null), clientProms: [] }
  );
  const clientsWithCases = await Promise.all(clientProms);
  // Get tasks for each case
  const { caseKeys, taskProms } = clientsWithCases.reduce(
    (acc, cur) => {
      if (cur.length) {
        cur.forEach((_case) => {
          acc.caseKeys[_case.case_id] = {
            ..._case,
            tasks: []
          };
          acc.taskProms.push(getTasksByCaseId(_case.case_id));
        });
      }
      return acc;
    },
    { caseKeys: Object.create(null), taskProms: [] }
  );
  const tasksResolved = await Promise.all(taskProms);
  // Merge tasks to their cases
  tasksResolved.forEach((tasks) => {
    if (tasks.length) {
      const { task } = tasks[0];
      if (caseKeys[task.case_id]) {
        caseKeys[task.case_id].tasks = tasks;
      }
    }
  });
  // Merge cases to their clients
  for (const key in caseKeys) {
    const _case = caseKeys[key];
    if (clientsKeys[_case.client_id]) {
      clientsKeys[_case.client_id].cases.push(_case);
    }
  }
  return Object.values(clientsKeys);
};

export const getTeamMembersById = async (teamId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });

export const getTeamAlertsById = async (teamId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamAlerts')
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach(async (doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });
export const getCommentsByCaseId = async (caseId) => {
  const comments = await new Promise((resolve, reject) => {
    firestore
      .collection('caseComment')
      .where('case_id', '==', caseId)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach(async (doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });
  const { arr } = comments.reduce(
    (acc, { user_id }) => {
      if (acc.store[user_id]) return acc;
      acc.store[user_id] = true;
      acc.arr.push(getUserByUserId(user_id));
      return acc;
    },
    { arr: [], store: {} }
  );
  const usersResolved = await Promise.all(arr);
  const users = [...usersResolved].reduce((acc, cur) => {
    acc[cur.user_id + ''] = { ...cur };
    return acc;
  }, Object.create(null));
  return {
    list: comments,
    users
  };
};

export const createTeamAlert = async ({ caseId, teamId, userId, type }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamAlerts')
      .add({
        case_id: caseId,
        team_id: teamId,
        type,
        user_id: userId
      })
      .then(resolve)
      .catch(reject);
  });

export const createCaseComment = async ({ case_id, message, userId }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('caseComment')
      .add({
        case_id,
        comment_id: uuidv4(),
        date: new Date().toISOString(),
        message,
        user_id: userId
      })
      .then(resolve)
      .catch(reject);
  });

export const createCasePermission = async ({
  case_id,
  identity,
  permissions,
  shared_mode,
  show_task_price,
  shared_by
}) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('casePermissions')
      .add({
        case_id,
        permission_id: uuidv4(),
        date_created: new Date().toISOString(),
        expire_date: new Date(new Date(new Date().toISOString()).getTime() + 60 * 60 * 24 * 7 * 1000).toISOString(),
        identity,
        permissions,
        shared_mode,
        show_task_price,
        shared_by,
        date:new Date().toISOString()
      })
      .then(resolve)
      .catch(reject);
  });

export const createCaseActivity = async ({ message, caseId, userId }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('caseActivity')
      .add({
        case_id: caseId,
        activity_id: uuidv4(),
        date: new Date().toISOString(),
        message,
        user_id: userId
      })
      .then(resolve)
      .catch(reject);
  });

export const getCaseById = async (caseId, status) => {
  let teamId = localStorage.getItem('teamId'); 

  if (status) {
    return new Promise((resolve, reject) => {
      firestore
        .collection('teamCases')
        .where('case_id', '==', caseId)
        .where('status', '==', status)
        .where('team_id', '==', teamId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            resolve(doc.data());
          });
          resolve({});
        })
        .catch(reject);
    });
  }
    return new Promise((resolve, reject) => {
      firestore
        .collection('teamCases')
        .where('case_id', '==', caseId)
        .where('team_id', '==', teamId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            resolve(doc.data());
          });
          resolve({});
        })
        .catch(reject);
    });
};

export const getTeamCasesById = async ({ userId, status }) => {
  let result = [];

  if (status === 'all') status = null;
  // Permissions
  const permissions = await getCasePermissionsByIdentity(userId);
  if (permissions.length === 0) return result;
  if (!status) {
    const { promises, caseIds } = permissions.reduce(
      (acc, perm) => {
        acc.caseIds[perm.case_id] = { ...perm };
        acc.promises.push(getCaseById(perm.case_id,''));
        return acc;
      },
      { promises: [], caseIds: {} }
    );
    const cases = await Promise.all(promises);
    cases.forEach((_case) => {
      if (caseIds[_case.case_id]) {
        result.push({
          ...caseIds[_case.case_id],
          ..._case
        });
      }
    });
  } else {
    const { promises, caseIds } = permissions.reduce(
      (acc, perm) => {
        acc.caseIds[perm.case_id] = { ...perm };
        acc.promises.push(getCaseById(perm.case_id, status));
        return acc;
      },
      { promises: [], caseIds: {} }
    );
    const cases = await Promise.all(promises);
    cases.forEach((_case) => {
      if (caseIds[_case.case_id]) {
        result.push({
          ...caseIds[_case.case_id],
          ..._case
        });
      }
    });
  }
  if (result.length === 0) return result;
  permissions.forEach((permission, idx) => {
    if (result[idx]) {
      result[idx].permission = permission;
    }
  });
  // Tasks
  const tasks = await Promise.all(
    result.reduce((acc, { case_id }) => {
      if (!case_id) return acc;
      acc.push(getTasksByCaseId(case_id));
      return acc;
    }, [])
  );
  tasks.forEach((task, idx) => (result[idx].tasks = task || []));
  // Clients
  const clients = await Promise.all(
    result.map(({ client_id }) => getClientById(client_id))
  );
  clients.forEach((client, idx) => (result[idx].client = client));
  // Result
  return result;
};

export const getActivitiesByCaseId = async (caseId) => {
  const activities = await new Promise((resolve, reject) => {
    firestore
      .collection('caseActivity')
      .where('case_id', '==', caseId)
      .get()
      .then((snapshot) => {
        const results = [];
        snapshot.forEach(async (doc) => {
          results.push(doc.data());
        });
        resolve(results);
      })
      .catch(reject);
  });
  if (!activities.length) return activities;
  const { proms } = activities.reduce(
    (acc, activity) => {
      if (acc.keeper[activity.user_id]) return acc;
      acc.keeper[activity.user_id] = true;
      acc.proms.push(getUserByUserId(activity.user_id));
      return acc;
    },
    { keeper: {}, proms: [] }
  );
  const users = await Promise.all(proms);
  const usersAsKeys = users.reduce((acc, curUser) => {
    acc[curUser.user_id] = curUser;
    return acc;
  }, {});
  return [...activities].map((activity) => {
    activity.user = usersAsKeys[activity.user_id];
    return activity;
  });
};

export const getTeamPaymentById = async (teamId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamPayment')
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
        resolve({});
      })
      .catch(reject);
  });

export const getStripeCustomerID = async ({ email }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('customers')
      .where('email', '==', email)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data().id);
        });
        resolve({});
        // const result = [];
        // snapshot.forEach((doc) => {
        //   result.push(doc.data());
        // });
        // var l_getCustomerID = result.stripeId;

        //resolve(l_getCustomerID);
      })
      .catch(reject);
  });

export const getClientTasksByClientId = async (clientId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('client_id', '==', clientId)
      .get()
      .then((snapshot) => {
        const tasks = [];
        snapshot.forEach((doc) => {
          tasks.push(doc.data());
        });
        //tasks.sort((a, b) => (a.sequence_no > b.sequence_no) ? 1 : -1);
        resolve(tasks);
      })
      .catch(reject);
  });

export const getCaseByCaseAndUser = async ({ caseId, userId }) => {
  const currentCase = await new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .where('case_id', '==', caseId)
      .where('allowed_ids', 'array-contains', userId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
        resolve({});
      })
      .catch(reject);
  });
  const [client, tasks, comments, activities] = await Promise.all([
    getClientById(currentCase.client_id),
    getTasksByCaseId(currentCase.case_id),
    getCommentsByCaseId(currentCase.case_id),
    getActivitiesByCaseId(currentCase.case_id)
  ]);
  return {
    ...currentCase,
    client,
    tasks,
    comments,
    activities
  };
};

export const createCase = async ({ teamId,userId, ...values }) => {
  const case_id = uuidv4();
  await new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .add({
        case_id,
        team_id: teamId,
        client_id: values.clientId,
        date: new Date().toISOString(),
        status: values.status,
        title: values.title,
        fake_case_id: values.fake_case_id,
        rate: values.rate,
        createdBy: userId
      })
      .then(resolve)
      .catch(reject);
  });
  return case_id;
};

export const createTaskMessage = async ({ taskId, userId, message }) => {
  const message_id = uuidv4();
  await new Promise((resolve, reject) => {
    firestore
      .collection('taskMessages')
      .add({
        message_id,
        user_id: userId,
        task_id: taskId,
        date: new Date().toISOString(),
        message
      })
      .then(resolve)
      .catch(reject);
  });
  return message_id;
};

export const getTaskMessages = async (taskId) => {
  if (!taskId) return [];
  const taskMessages = await new Promise((resolve, reject) => {
    firestore
      .collection('taskMessages')
      .where('task_id', '==', taskId)
      .get()
      .then((snapshot) => {
        const messages = [];
        snapshot.forEach((doc) => {
          messages.push(doc.data());
        });
        resolve(messages);
      })
      .catch(reject);
  });
  const taskUsers = await Promise.all(
    taskMessages.map((message) => getUserByUserId(message.user_id))
  );
  taskUsers.forEach((user, idx) => {
    taskMessages[idx].user = user;
  });
  const res = taskMessages.sort((a, b) => new Date(b.date) - new Date(a.date));
  return res;
};

export const getTasksByClientId = async (clientId) => {
  const tasks = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('client_id', '==', clientId)
      .get()
      .then((snapshot) => {
        const tasks = [];
        snapshot.forEach((doc) => {
          tasks.push(doc.data());
        });
        //tasks.sort((a, b) => (a.sequence_no > b.sequence_no) ? 1 : -1);
        resolve(tasks);
      })
      .catch(reject);
  });
  const sorted = tasks.sort(
    (a, b) => a.parent_task_id.length - b.parent_task_id.length
  );
  const { keys } = sorted.reduce(
    (acc, task) => {
      if (!task.parent_task_id) {
        acc.keys[task.task_id] = {
          task,
          tasks: []
        };
      } else {
        if (acc.keys[task.parent_task_id]) {
          acc.keys[task.parent_task_id].tasks.push({ task, tasks: [] });
        }
      }
      return acc;
    },
    { keys: {} }
  );
  return Object.values(keys);
};

export const getTasksByCaseId = async (caseId) => {
  const tasks = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('case_id', '==', caseId)
      .get()
      .then((snapshot) => {
        const tasks = [];
        snapshot.forEach((doc) => {
          tasks.push(doc.data());
        });
        //tasks.sort((a, b) => (a.sequence_no > b.sequence_no) ? 1 : -1);
        resolve(tasks);
      })
      .catch(reject);
  });
  const taskMessages = await Promise.all(
    tasks.map((task) => getTaskMessages(task.task_id))
  );
  tasks.forEach((_, idx) => {
    tasks[idx].messages = taskMessages[idx];
  });
  const sorted = tasks.sort(
    (a, b) => a.parent_task_id.length - b.parent_task_id.length
  );
  const { keys } = sorted.reduce(
    (acc, task) => {
      if (!task.parent_task_id) {
        acc.keys[task.task_id] = {
          task,
          tasks: []
        };
      } else {
        if (acc.keys[task.parent_task_id]) {
          acc.keys[task.parent_task_id].tasks.push({ task, tasks: [] });
        }
      }
      return acc;
    },
    { keys: {} }
  );
  return Object.values(keys);
};

export const getTaskById = async (taskId) => {
  const task = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('task_id', '==', taskId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.data());
        });
        resolve({});
      })
      .catch(reject);
  });
  const group = {
    task: _.cloneDeep(task)
  };
  let [subtasks, taskMessages] = await Promise.all([
    getTasksByParentId(group.task.task_id),
    getTaskMessages(group.task.task_id)
  ]);
  group.task.messages = taskMessages;
  subtasks = _.cloneDeep(subtasks);
  const subTasksMessages = await Promise.all(
    subtasks.map((s) => getTaskMessages(s.task_id))
  );
  subTasksMessages.forEach(
    (msg, idx) => (subtasks[idx].messages = _.cloneDeep(msg))
  );
  group.tasks = subtasks.map((subTask) => ({ task: subTask, tasks: [] }));
  return group;
};

export const getTasksByParentId = async (parentId) => {
  let tasks = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('parent_task_id', '==', parentId)
      .get()
      .then((snapshot) => {
        const result = [];
        snapshot.forEach((doc) => {
          result.push(doc.data());
        });
        //result.sort((a, b) => (a.sequence_no > b.sequence_no) ? 1 : -1);
        resolve(result);
      })
      .catch(reject);
  });
  tasks = _.cloneDeep(tasks);
  let taskMessages = await Promise.all(
    tasks.map((task) => getTaskMessages(task.task_id))
  );
  taskMessages = _.cloneDeep(taskMessages);
  tasks.forEach((_, idx) => {
    tasks[idx].messages = taskMessages[idx];
    tasks[idx].tasks = [];
  });
  return tasks;
};

export const getTasksByCategoryId = async (categoryId) => {
  const tasks = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('category_id', '==', categoryId)
      .get()
      .then((snapshot) => {
        const tasks = [];
        snapshot.forEach((doc) => {
          tasks.push(doc.data());
        });
        //tasks.sort((a, b) => (a.sequence_no > b.sequence_no) ? 1 : -1);
        resolve(tasks);
      })
      .catch(reject);
  });
  const taskMessages = await Promise.all(
    tasks.map((task) => getTaskMessages(task.task_id))
  );
  tasks.forEach((_, idx) => {
    tasks[idx].messages = taskMessages[idx];
  });
  const sorted = tasks.sort(
    (a, b) => a.parent_task_id.length - b.parent_task_id.length
  );
  const { keys } = sorted.reduce(
    (acc, task) => {
      if (!task.parent_task_id) {
        acc.keys[task.task_id] = {
          task,
          tasks: []
        };
      } else {
        if (acc.keys[task.parent_task_id]) {
          acc.keys[task.parent_task_id].tasks.push({ task, tasks: [] });
        }
      }
      return acc;
    },
    { keys: {} }
  );
  return Object.values(keys);
};

export const deleteTask = async (taskId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('task_id', '==', taskId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.ref.delete());
        });
      })
      .catch(reject);
  });

export const copyTask = async ({ taskId, caseId }) => {
  if (!taskId) return;
  // Seek task
  const group = await getTaskById(taskId);
  if (!group?.task?.task_id) return;
  // Clone tasks to copy and run through a BFS algorithm
  const queue = [group];

  while (queue.length) {
    const { task, tasks } = queue.shift();
    if (task.parent_task_id == "") {
      var l_category_sequence_no = await getCategoryMaxSequenceNo({ case_id: caseId });
    }
    else {
      var l_task_sequence_no = await getTaskMaxSequenceNo({ caseId, parentTaskId: task.parent_task_id, categoryId: task.category_id });
    };
    const taskId = await createTask({
      categoryId: task.category_id,
      caseId: task.case_id,
      clientId: task.client_id,
      parentTaskId: task.parent_task_id,
      assignedUser: task.assignedUser,
      hours: task.hours,
      rate: task.rate,
      title: task.title,
      task_sequence_no: l_task_sequence_no || 0,
      category_sequence_no: l_category_sequence_no || 0,
    });
    if (tasks.length) {
      tasks.forEach((i) => {
        const obj = _.cloneDeep(i);
        obj.task.parent_task_id = taskId;
        queue.push(obj);
      });
    }
  }
};

export const deleteClient = async (clientId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamClients')
      .where('client_id', '==', clientId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.ref.delete());
        });
      })
      .catch(reject);
  });

export const deleteUserFromTeam = async ({ userId, teamId }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('user_id', '==', userId)
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.ref.delete());
        });
      })
      .catch(reject);
  });

  export const deleteUserFromTeamByUserID = async ({ userId,permissions }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('user_id', '==', userId)
      .where('permissions', '==', permissions)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.ref.delete());
        });
      })
      .catch(reject);
  });

  export const updateUserFromTeamByUserID = async ({ userId,teamId }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('user_id', '==', userId)
      .where('team_id', '==', teamId)
      .where("permissions", "array-contains-any", ["invited"])
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          var l_result = doc.data();
          l_result.permissions[0] = l_result.requestPermissions;
          resolve(doc.ref.update(l_result));
        });
        resolve();
      })
      .catch(reject);
  });

  export const updateUserIDTeamMembers = async ({ userId,teamId,newUserID }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('teamMembers')
      .where('user_id', '==', userId)
      .where('team_id', '==', teamId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          var l_result = doc.data();
          l_result.user_id = newUserID;
          resolve(doc.ref.update(l_result));
        });
        resolve();
      })
      .catch(reject)
  });


  export const updateUserBySigninWithTeam = async ({ userObj }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('users')
      .where('user_id', '==', userObj.user_id)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          var l_result = doc.data();
          l_result.first_name = userObj.first_name;
          l_result.last_name = userObj.last_name;
          l_result.email = userObj.email;
          l_result.avatar = userObj.avatar;
          l_result.isGoogleUser = userObj.isGoogleUser;
          l_result.activeUser = userObj.activeUser;
          l_result.password = userObj.password;
          resolve(doc.ref.update(l_result));
        });
        resolve();
      })
      .catch(reject);
  });

  export const swapuserID = async ({ email,newUserID }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('users')
      .where('email', '==', email)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          var l_result = doc.data();
          l_result.user_id = newUserID;
         
          resolve(doc.ref.update(l_result));
        });
        resolve();
      })
      .catch(reject);
  });

export const deleteUser = async ({ email }) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('users')
      .where('email', '==', email)
      .where('first_name', '==', "")
      .where('activeUser', '==', 0)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(doc.ref.delete());
        });
        resolve();
      })
      
  });

export const updateCase = async ({ caseId, date, ...values }) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .where('case_id', '==', caseId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            doc.ref.set({
              ...doc.data(),
              ...values
            })
          );
        });
      })
      .catch(reject);
  });
};

export const updateNotes = async ({ caseId, ...values }) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('teamCases')
      .where('case_id', '==', caseId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          resolve(
            doc.ref.set({
              ...doc.data(),
              ...values
            })
          );
        });
      })
      .catch(reject);
  });
};

// export const updateCase_rate = async ({ caseId, date, ...values }) => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection('caseTask')
//       .where('case_id', '==', caseId)
//       .get()
//       .then((snapshot) => {
//         snapshot.forEach((doc) => {
//           resolve(
//             doc.ref.set({
//               ...doc.data(),
//               ...values
//             })
//           );
//         });
//       })
//       .catch(reject);
//   });
// };


// export const updateTask = async ({newTaskData, taskId, ...values }) =>
//   new Promise((resolve, reject) => {
//     firestore
//       .collection('caseTask')
//       .where('task_id', '==', taskId)
//       .get()
//       .then((snapshot) => {
//         if (snapshot.size >= 1) {
//           snapshot.forEach((doc) => {
//             resolve(
//               doc.ref.set({
//                 ...doc.data(),
//                 ...values
//               })
//             );
//           });
//         }
//         else
//         {
//           var data =  _.cloneDeep(values.categories[0].tasks[0].task);
//           var NewData = {};
//           NewData.categoryId = data.category_id || '';
//           NewData.caseId = data.case_id || '';
//           NewData.clientId = data.clientId || '';
//           NewData.parentTaskId = data.parentTaskId || '';
//           NewData.assignedUser = data.assignedUser || '';
//           NewData.hours = data.hours || 0;
//           NewData.rate = data.rate || 0;
//           NewData.title = data.title  || '';
//           NewData.category_sequence_no = data.category_sequence_no || 0;
//           NewData.task_sequence_no = data.task_sequence_no || 0;
//           createTask(NewData);
//         }
//       })
//       .catch(reject);
//   });

export const updateTask = async ({ taskId, ...values }, thunkAPI) => {
  const resultPromise = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('task_id', '==', taskId)
      .get()
      .then((snapshot) => {
        var data = null;
        var categories = thunkAPI.getState().tasks.list[0];

        if (categories.tasks && categories.tasks.length > 0) {
          categories.tasks.forEach((doc) => {
            if (data) {
              return;
            }

            if (doc.task.task_id == taskId) {
              data = _.cloneDeep(doc.task);
              return;
            }
            else {
              doc.tasks.forEach((obj) => {
                if (obj.task.task_id == taskId) {
                  data = _.cloneDeep(obj.task);
                  return;
                }
              });
            }
          });
        }

        if (snapshot.size >= 1) {
          snapshot.forEach((doc) => {
            if (data == null) {
              resolve(doc.ref.delete());
            }
            else {
              resolve(
                doc.ref.set({
                  ...doc.data(),
                  ...data
                })
              );
            }
          });
        }
        else {
          if (data) {
            var l_Data = [];
            var NewData = {};
            NewData.task_id = data.task_id || '';
            NewData.categoryId = data.category_id || '';
            NewData.caseId = data.case_id || '';
            NewData.clientId = data.client_id || '';
            NewData.parentTaskId = data.parent_task_id || '';
            NewData.assignedUser = data.assignedUser || '';
            NewData.hours = data.hours || 0;
            NewData.notes = data.notes || '';
            NewData.rate = data.rate || 0;
            NewData.title = data.title || '';
            NewData.category_sequence_no = data.category_sequence_no || 0;
            NewData.task_sequence_no = data.task_sequence_no || 0;

            l_Data.push(NewData);
            resolve(l_Data);
          }
        }
      })
      .catch(reject);
  });
  const { promises, categoryKeys } = resultPromise.reduce(
    (acc, cur) => {
      createNewTask(cur);
      return acc;
    },
    { promises: [], categoryKeys: Object.create(null) }
  );
  const groupedTasks = await Promise.all(promises);
};

export const taskSwap = async ({ caseId, parentTaskID, sourceTaskId, destinatioTaskId }) => {
  var l_Data = [];
  var l_DataList = [];
  var l_Result = [];

  const categories = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('case_id', '==', caseId)
      .where('parent_task_id', '==', parentTaskID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          l_DataList.push(doc);
        });
        var l_SourceData;
        var l_DestinationIndex = 0;


        l_DataList.forEach((doc) => {
          if (doc.data().task_id == sourceTaskId) {
            l_SourceData = doc;
          }
          else {
            l_Result.push(doc);

            if (doc.data().task_id == destinatioTaskId) {
              l_DestinationIndex = doc.data().task_sequence_no;
            }
          }
        });

        l_DestinationIndex = l_DestinationIndex - 1;
        if (l_DestinationIndex < 0) {
          l_DestinationIndex = 0;
        }

        l_Result.sort((a, b) => (a.data().task_sequence_no > b.data().task_sequence_no) ? 1 : -1);

        l_Result.splice(l_DestinationIndex, 0, l_SourceData)

        var l_Index = 1;

        l_Result.forEach((doc) => {
          var l_datata = doc.data();
          l_datata.task_sequence_no = l_Index;
          l_Index++;
          l_Data.push(l_datata);
        });


        resolve(l_DataList);
      })
      .catch(reject);
  });
  const { promises, categoryKeys } = categories.reduce(
    (acc, cur) => {
      var l_value;
      l_Data.forEach((doc) => {
        if (doc.task_id == cur.data().task_id) {
          l_value = doc.task_sequence_no;
        }
      });
      firestore
        .collection('caseTask').doc(cur.id).update({
          task_sequence_no: l_value,
        });
      return acc;
    },
    { promises: [], categoryKeys: Object.create(null) }
  );
  const groupedTasks = await Promise.all(promises);
};


export const categorySwap = async ({ caseId, parentTaskID, sourceTaskId, destinatioTaskId }) => {
  var l_Data = [];
  var l_DataList = [];
  var l_Result = [];

  const categories = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('case_id', '==', caseId)
      .where('task_id', 'in', parentTaskID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          l_DataList.push(doc);
        });
        var l_SourceData;
        var l_DestinationIndex = 0;


        l_DataList.forEach((doc) => {
          if (doc.data().task_id == sourceTaskId) {
            l_SourceData = doc;
          }
          else {
            l_Result.push(doc);

            if (doc.data().task_id == destinatioTaskId) {
              l_DestinationIndex = doc.data().category_sequence_no;
            }
          }
        });

        l_DestinationIndex = l_DestinationIndex - 1;
        if (l_DestinationIndex < 0) {
          l_DestinationIndex = 0;
        }

        l_Result.sort((a, b) => (a.data().category_sequence_no > b.data().category_sequence_no) ? 1 : -1);

        l_Result.splice(l_DestinationIndex, 0, l_SourceData)

        var l_Index = 1;

        l_Result.forEach((doc) => {
          var l_datata = doc.data();
          l_datata.category_sequence_no = l_Index;
          l_Index++;
          l_Data.push(l_datata);
        });


        resolve(l_DataList);
      })
      .catch(reject);
  });
  const { promises, categoryKeys } = categories.reduce(
    (acc, cur) => {
      var l_value;
      l_Data.forEach((doc) => {
        if (doc.task_id == cur.data().task_id) {
          l_value = doc.category_sequence_no;
        }
      });
      firestore
        .collection('caseTask').doc(cur.id).update({
          category_sequence_no: l_value,
        });
      return acc;
    },
    { promises: [], categoryKeys: Object.create(null) }
  );
  const groupedTasks = await Promise.all(promises);
};


export const destinationTaskSwap = async ({ taskId, values }) => {
  var l_docid = '';
  var l_getid = await new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('task_id', '==', taskId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          l_docid = doc.id;
          resolve();
        });

      })
      .catch(reject);
  });
  setTimeout(function () {
    firestore
      .collection('caseTask').doc(l_docid).update({

        sequence_no: values,

      });
  }, 2000);

};

export const createTask = async ({
  categoryId,
  caseId,
  clientId,
  parentTaskId,
  assignedUser,
  hours,
  rate,
  title,
  category_sequence_no,
  task_sequence_no
}) => {
  const task_id = uuidv4();
  return new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .add({
        task_id,
        parent_task_id: parentTaskId,
        case_id: caseId,
        client_id: clientId,
        category_id: categoryId,
        date: new Date().toISOString(),
        assignedUser: assignedUser || '',
        hours,
        notes: '',
        offset: 0,
        rate,
        title: title || '',
        category_sequence_no,
        task_sequence_no
      })
      .then(() => {
        resolve(task_id);
      })
      .catch(reject);
  });
};

export const createNewTask = async ({
  task_id,
  categoryId,
  caseId,
  clientId,
  parentTaskId,
  assignedUser,
  hours,
  notes,
  rate,
  title,
  category_sequence_no,
  task_sequence_no
}) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .add({
        task_id: task_id,
        parent_task_id: parentTaskId,
        case_id: caseId,
        client_id: clientId,
        category_id: categoryId,
        date: new Date().toISOString(),
        assignedUser: assignedUser || '',
        hours: hours || 0,
        notes: notes || '',
        offset: 0,
        rate: rate || 0,
        title: title || '',
        category_sequence_no: category_sequence_no || 0,
        task_sequence_no: task_sequence_no || 0
      })
      .then(() => {
        resolve(task_id);
      })
      .catch(reject);
  });
};

export const createCategory = async ({
  categoryId,
  caseId,
  clientId,
  parentTaskId,
  assignedUser,
  hours,
  rate,
  title,
  category_sequence_no,
  task_sequence_no
}) => {
  const task_id = uuidv4();
  return new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .add({
        task_id,
        parent_task_id: parentTaskId,
        case_id: caseId,
        client_id: clientId,
        category_id: categoryId,
        date: new Date().toISOString(),
        assignedUser: assignedUser || '',
        hours,
        notes: '',
        offset: 0,
        rate,
        title: title || '',
        category_sequence_no,
        task_sequence_no: 0
      })
      .then(() => {
        resolve(task_id);
      })
      .catch(reject);
  });
};

export const createCaseCategory = async ({ title, case_id }) => {
  const category_id = uuidv4();
  return new Promise((resolve, reject) => {
    firestore
      .collection('caseCategories')
      .add({
        case_id,
        category_id,
        date: new Date().toISOString(),
        title
      })
      .then(() => {
        resolve(category_id);
      })
      .catch(reject);
  });
};

export const getCategoryMaxSequenceNo = async ({ case_id }) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('parent_task_id', '==', '')
      .where('case_id', '==', case_id)
      // .orderBy("date", 'desc').limit(1)
      .get()
      .then((snapshot) => {
        const result = [];
        snapshot.forEach((doc) => {
          result.push(doc.data());
        });
        var l_getCategoryMaxSequenceNo = 0;
        var l_length = 0;
        if (result.length == 0) {
          l_getCategoryMaxSequenceNo = 1;
        }
        else {
          result.sort((a, b) => (a.category_sequence_no > b.category_sequence_no) ? 1 : -1);
          l_length = result.length - 1;
          l_getCategoryMaxSequenceNo = result[l_length].category_sequence_no + 1;
        };

        resolve(l_getCategoryMaxSequenceNo);
      })
      .catch(reject);
  });
};

export const getTaskMaxSequenceNo = async ({ caseId, parentTaskId, categoryId }) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('caseTask')
      .where('parent_task_id', '==', parentTaskId)
      .where('case_id', '==', caseId)
      .where('category_id', '==', categoryId)
      // .orderBy("date", 'desc').limit(1)
      .get()
      .then((snapshot) => {
        const result = [];
        snapshot.forEach((doc) => {
          result.push(doc.data());
        });

        var l_getTaskMaxSequenceNo = 0;
        var l_length = 0;
        if (result.length == 0) {
          l_getTaskMaxSequenceNo = 1;
        }
        else {
          result.sort((a, b) => (a.task_sequence_no > b.task_sequence_no) ? 1 : -1);
          l_length = result.length - 1;
          l_getTaskMaxSequenceNo = result[l_length].task_sequence_no + 1;
        };

        resolve(l_getTaskMaxSequenceNo);
      })
      .catch(reject);
  });
};

export const getCaseCategoriesByCaseId = async (caseId) => {
  const categories = await new Promise((resolve, reject) => {
    firestore
      .collection('caseCategories')
      .where('case_id', '==', caseId)
      .get()
      .then((snapshot) => {
        const result = [];
        snapshot.forEach((doc) => {
          result.push(doc.data());
        });
        resolve(result);
      })
      .catch(reject);
  });
  const { promises, categoryKeys } = categories.reduce(
    (acc, cur) => {
      if (cur?.category_id) {
        acc.promises.push(getTasksByCategoryId(cur.category_id));
        acc.categoryKeys[cur.category_id] = {
          category: cur,
          tasks: []
        };
      }
      return acc;
    },
    { promises: [], categoryKeys: Object.create(null) }
  );

  const groupedTasks = await Promise.all(promises);
  groupedTasks.forEach((group) => {
    if (group.length) {
      const item = group[0];
      if (categoryKeys[item.task.category_id]) {
        group.forEach((i) => {
          categoryKeys[i.task.category_id].tasks.push(i);
        });
      }
    }
  });
  for (const key in categoryKeys) {
    const shallow = [...categoryKeys[key].tasks];
    shallow.forEach(({ tasks }, idx) => {
      shallow[idx].tasks = tasks.sort(
        (a, b) => new Date(a.task.date) - new Date(b.task.date)
      );
    });
    categoryKeys[key].tasks = shallow.sort(
      (a, b) => new Date(a.task.date) - new Date(b.task.date)
    );
  }
  return Object.values(categoryKeys).sort(
    (a, b) => new Date(a.category.date) - new Date(b.category.date)
  );
};

export const createTeamActivity = async ({ message, teamId }) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('teamAlerts')
      .add({
        team_id: teamId,
        date: new Date().toISOString(),
        activity_id: uuidv4(),
        message
      })
      .then(resolve)
      .catch(reject);
  });
};

export const checkPlan = async ({ teamId }) => {
  // return new Promise((resolve, reject) => {
  //   firestore
  //     .collection('teamAlerts')
  //     .add({
  //       team_id: teamId,
  //       date: new Date().toISOString(),
  //       activity_id: uuidv4(),
  //       message
  //     })
  //     .then(resolve)
  //     .catch(reject);
  // });
  return false;
};

