import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Login from 'src/content/pages/Auth/Login';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';
import ClientShareBudget from 'src/components/ClientShareBudget';
import SidebarLayout from 'src/layouts/SidebarLayout';
import OverlayLoader from 'src/components/OverlayLoader';

export const renderRoutes = (routes) => (
  <Suspense fallback={<OverlayLoader visible={true} loadingText={"Loading..."} />}>
    <Switch>
      {routes.map((route, idx) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: '/secure',
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        path: '/secure/onboarding/teams/:teamId?',
        component: lazy(() => import('src/content/pages/Onboarding'))
      },
      {
        exact: true,
        path: '/secure/profile',
        component: lazy(() => import('src/content/pages/Profile'))
      },
      {
        exact: true,
        path: '/secure/clients',
        component: lazy(() => import('src/content/pages/Clients'))
      },
      {
        exact: true,
        path: '/secure/clients/create',
        component: lazy(() => import('src/content/pages/ClientsCreate'))
      },
      {
        exact: true,
        path: '/secure/budgets/create',
        component: lazy(() => import('src/content/pages/EstimatesCreate'))
      },
      {
        exact: true,
        path: '/secure/budgets/:clientId?',
        component: lazy(() => import('src/content/pages/Budgets'))
      },
      {
        exact: true,
        path: '/secure/:caseId/case/:clientId?',
        component: lazy(() => import('src/content/pages/Case'))
      },
      {
        exact: true,
        path: '/secure/PaymentPlan',
        component: lazy(() => import('src/content/pages/PaymentPlan'))
      },
      {
        exact: true,
        path: '/secure/SharedBudgets',
        // component: () => <Redirect to="/secure/budgets" />
        component: lazy(() => import('src/content/pages/SharedBudgets'))
      },
      {
        exact: true,
        path: '/secure/SharedBudgets/:clientId?',
        component: lazy(() => import('src/content/pages/SharedBudgets'))
      },
      {
        exact: true,
        path: '/secure*',
        component: () => <Redirect to="/secure/budgets" />
        //component: lazy(() => import('src/content/pages/Budgets'))
      },
    ]
  },
  {
    exact: true,
    guard: ClientShareBudget,
    path: '/shared/case/:caseId/identity/:identity',
    component: lazy(() => import('src/content/pages/CaseShared'))
  },
  {
    exact: true,
    guard: Authenticated,
    layout: SidebarLayout,
    path: '/secure/:type/type/:caseId/case',
    component: lazy(() => import('src/content/pages/CaseShared'))
  },
  {
    exact: true,
    guard: Authenticated,
    path: '/shared/type/:type/preview/case/:caseId/identity/:identity',
    component: lazy(() => import('src/content/pages/CaseShared'))
  },
  {
    exact: true,
    guard: Guest,
    path: '/login',
    component: lazy(() => import('src/content/pages/Auth/Login'))
  },
  {
    exact: true,
    guard: Guest,
    path: '/login/:teamId/:email',
    component: lazy(() => import('src/content/pages/Auth/Login'))
  },
  // {
  //   exact: true,
  //   guard: Guest,
  //   path: '/register/:teamId/:email',
  //   component: lazy(() => import('src/content/pages/Auth/Register'))
  // },
  {
    exact: true,
    guard: Guest,
    path: '/register/:teamId?/:email?',
    component: lazy(() => import('src/content/pages/Auth/Register'))
  },
  {
    exact: true,
    guard: Guest,
    path: '/recover-password/:teamId/:email',
    component: lazy(() => import('src/content/pages/Auth/RecoverPassword'))
  },
  {
    exact: true,
    guard: Guest,
    path: '/recover-password',
    component: lazy(() => import('src/content/pages/Auth/RecoverPassword'))
  },
  // {
  //   exact: true,
  //   path: '/status/404',
  //   component: lazy(() => import('src/content/pages/Status/Status404'))
  // },
  // {
  //   exact: true,
  //   path: '/status/500',
  //   component: lazy(() => import('src/content/pages/Status/Status500'))
  // },
  {
    path: '*',
    routes: [
      {
        exact: true,
        guard: Guest,
        path: '/',
        component: Login
      },
      {
        component: () => <Redirect to="/" />
      }
    ]
  }
];

export default routes;
