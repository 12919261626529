import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { experimentalStyled } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import Sidebar from './Sidebar';

const MainContent = experimentalStyled(Box)(
  ({ theme, offset }) => `
  flex: 1 1 auto;
  overflow: auto;
  padding-top: ${theme.spacing(3)};
  padding-bottom: ${theme.spacing(3)};
  padding-left: ${offset}px;
`
);

function AccentHeaderLayout({ children }) {
  const [rect, setRect] = useState({});

  const sidebarRef = useCallback((node) => {
    if (node) {
      const observer = new ResizeObserver(function (entries) {
        for (let entry of entries) {
          setRect(entry.contentRect);
        }
      });
      observer.observe(node);
    }
  }, []);

  const offset = useMemo(() => {
    return rect.width + rect.left;
  }, [rect.width]);

  return (
    <Layout className='main-wrapper' style={{paddingRight:"0px"}}>
      <Sidebar ref={sidebarRef} />
      <MainContent className='rightside' offset={offset || 0}>{children}</MainContent>
    </Layout>
  );
}

AccentHeaderLayout.propTypes = {
  children: PropTypes.node
};

export default AccentHeaderLayout;

const Layout = experimentalStyled(Container)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.secureBackground,
  width: '100%',
  maxWidth: '1500px', // Total width of 1148 + 12 + 200 = 1360
  height: '100vh'
}));
