export default {
  // Team Members
  admin: ['admin', 'create', 'edit', 'read', 'comment'],
  // create: ['member', 'create', 'edit', 'read', 'comment'],
  // edit: ['member', 'edit', 'read', 'comment'],
  // comment: ['member', 'read', 'comment'],
  // read: ['member', 'read'],
  user:['member', 'create', 'edit', 'read', 'comment'],
  invite:['invited'],
  // Clients
  clientView: ['client', 'read'],
  clientEdit: ['client', 'read', 'comment'] // Edit means chat
};

export const getPermissionLevelForTeamMembers = (arr = []) => {
  if (!Array.isArray(arr)) return null;
  if (Array.isArray(arr) && !arr.some((i) => i === 'admin' || i === 'member'||i==='invited'))
    return null;
  if (arr.includes('admin')) {
    return 'admin';
  }
  if (arr.includes('create')) {
    return 'user';
  }
  if (arr.includes('edit')) {
    return 'user';
  }
  if (arr.includes('user')) {
    return 'user';
  }
  if (arr.includes('invited')) {
    return 'invited';
  }
  if (arr.includes('comment')) {
    return 'user';
  }
  return 'user';
};

export const teamPermissionText = {
  admin: 'Admin',
  // create: 'User',
  // edit: 'User',
  // read: 'User',
  user:'User'
};

export const getPermissionLevelForClients = (arr = []) => {
  if (!arr.includes('client')) return null;
  if (arr.includes('comment')) {
    return 'comment';
  }
  return 'read';
};
