function Loadable() {
  return {
    createDefaultLoadable() {
      return {
        loading: false,
        success: false,
        error: null
      };
    },
    createLoadingLoadable() {
      return {
        loading: true,
        success: false,
        error: null
      };
    },
    createSuccessLoadable() {
      return {
        loading: false,
        success: true,
        error: null
      };
    },
    createErrorLoadable(error) {
      return {
        loading: false,
        success: false,
        error
      };
    }
  };
}

export default Loadable;
