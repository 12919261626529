import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Loadable from 'src/utils/loadable';
import {
  createClient,
  createTeam,
  createTeamActivity,
  getTeamAlertsById,
  getTeamMemberByUserId,
  getTeamClientsByTeamId,
  getTeamMembersById,
  getTeamPaymentById,
  getTeams,
  getUserByUserId,
  updateTeamName,
  updateTeamPlanById,
  updateTeamMember,
  updateTeamMemberPermissions,
  updateClient,
  addTeamMember,
  deleteClient,
  deleteUserFromTeam,
  getTeamCasesById,
  getTasksByCaseId,
  getTeamByTeamId,
  checkIfTeamOwner,
  getUserByEmail,
  createCasePermission,
  checkPlan,
  registerUser,
  createCaseActivity,
  getStripeCustomerID,
  getCustomersDetail,
  getTeamOwnerEmail,
  getTeamMemberByTeamID,
  updateUserFromTeamByUserID,
  getUsers,
} from 'src/network';
import { getUserAsync } from './user';
import { clearList } from './cases';
import { feehiveAPI } from 'src/utils/axios';
import teamMemberPermissions from 'src/utils/teamMembers';
import Logo from 'src/assets/image/logo.png';
import { format } from 'date-fns';
import { emailBody } from 'src/components/MailBody';
import { intviteTeamEmail } from 'src/components/InviteTeamEmail';
import { v4 as uuidv4 } from 'uuid';
import {getCaseByIdAsync,setCaseEditing, setCaseSharing} from 'src/slices/cases';
import useAuth from 'src/hooks/useAuth';
import { Redirect, useParams } from 'react-router-dom';
import { getSubscription} from "src/content/pages/stripe/createCheckoutSession";

const initialState = {
  list: [],
  team: {},
  teamFull: {
    clients: [],
    alerts: [],
    cases: []
  },
  sendInvite: false,
  inviteMessage: "",
  sharedBudegetUsername: "",
  selectedClientId: '',
  customerID:"",
  customerDetail:[],
  joinLoadable: Loadable().createDefaultLoadable(),
  listLoadable: Loadable().createDefaultLoadable(),
  teamLoadable: Loadable().createDefaultLoadable(),
  teamFullLoadable: Loadable().createDefaultLoadable(),
  teamCreateLoadable: Loadable().createDefaultLoadable(),
  teamClientsLoadable: Loadable().createDefaultLoadable(),
  teamClientCreateLoadable: Loadable().createDefaultLoadable(),
  teamNameUpdateLoadable: Loadable().createDefaultLoadable(),
  leaveTeamLoadable: Loadable().createDefaultLoadable(),
  teamAcceptMemberLoadable: Loadable().createDefaultLoadable(),
  teamRemoveMemberLoadable: Loadable().createDefaultLoadable(),
  createClientInCaseLoadable: Loadable().createDefaultLoadable()
};



export const getTeamAsync = createAsyncThunk('team/Get', async (userId) => {
  let membershipData = [];
  let membership = [];
  let team = localStorage.getItem('teamId') ? localStorage.getItem('teamId') : '';

  if(team && team !='undefined')
  {
    membershipData = await getTeamMemberByTeamID(userId,team);

    if(membershipData.length > 1)
    {
      membershipData.forEach((doc) => {
        let l_Result;
        l_Result = membership.some(item => item.team_id === doc.team_id);
      
        if(!l_Result)
        {
          membership.push(doc);
        } 
      });
    }
    else
    {
      membership = membershipData;
    }
  }
  else
  {
    membershipData = await getTeamMemberByUserId(userId);

    membershipData.forEach((doc) => {
      let l_Result;
      l_Result = membership.some(item => item.team_id === doc.team_id);
    
      if(!l_Result)
      {
        membership.push(doc);
      } 
    });
  }

  if(membership && membership.length > 1)
  {
    const result = '';

    for (const val of membership) {
      if(val.team_id)
      {
        const result =  await (getTeamByTeamId(val.team_id));

        if(result)
        {
          let text = '';

          if(result.owner === userId)
          {
            text = 'Own Team';
          }
          else
          {
            text = result.team_name;
          }

          membership.forEach((val) => {
              if(val.team_id && val.team_id === result.team_id)
              {
                val.text = text;
              }
            });
        }
      }
    }

    return {
      ...result,
      membership
    };
  }

  if (!membership[0]?.team_id) return {};

  localStorage.setItem('teamId',membership[0].team_id);

  const result = await getTeamByTeamId(membership[0].team_id);
  return {
    ...result,
    membership
  };
});

export const getTeamDetail = createAsyncThunk('team/GetDetail', async (teamId) => {
  const result = await getTeamByTeamId(teamId);

  return result;
});

export const updateTeamMembership = createAsyncThunk('team/UpdateTeamMembership', async ({ userId,teamId }) => {
  const result = await updateUserFromTeamByUserID({userId:userId,teamId:teamId});

  return result;
});

export const getTeamsAsync = createAsyncThunk('teams/Get', async () => {
  const teams = await getTeams();
  const promises = teams.map(({ owner }) => getUserByUserId(owner));
  const users = await Promise.all(promises);
  const shallow = [...teams];
  users.forEach((user, idx) => (shallow[idx].user = user));
  return shallow;
});

export const createTeamAsync = createAsyncThunk(
  'team/Create',
  async ({ teamName, userId }) => {
    const [teamOwner] = await Promise.all([checkIfTeamOwner(userId)]);
    if (teamOwner.team_id) throw new Error();
    return createTeam({ teamName, userId });
  }
);
export const deleteTeamAsync = createAsyncThunk(
  'team/Delete',
  async (teamId) => {
    return null;
  }
);
export const updateTeamPlanAsync = createAsyncThunk(
  'team/Plan/Update',
  async (plan, thunkAPI) => {
    if (!plan) return;
    const state = thunkAPI.getState();
    const teamId = state.teams?.team?.team_id;
    if (teamId) {
      await updateTeamPlanById({
        teamId,
        plan
      });
      const userId = state.user?.id;
      if (userId) thunkAPI.dispatch(getTeamAsync(user.id));
    }
  }
);
export const updateTeamNameAsync = createAsyncThunk(
  'team/Name/Update',
  async (teamName, thunkAPI) => {
    const teams = thunkAPI.getState().teams;
    const teamId = teams.team.team_id;
    const currentUser = thunkAPI.getState().user;
    thunkAPI.dispatch(getTeamFullAsync({teamId : teamId, userId : ""}));
    return Promise.all([
      updateTeamName({ teamName, teamId }),
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} updated the team name`,
        teamId
      })
    ]);
  }
);
export const getCustomerID = createAsyncThunk(
  'team/get/GetCustomerID',
  async (email, thunkAPI) => {
    var l_customerID = await getStripeCustomerID({email});
    if(l_customerID)
    {
      thunkAPI.dispatch(setCustomerID(l_customerID));
    }
    else
    {
      thunkAPI.dispatch(setCustomerID(''));
    }
    return l_customerID;
  }
);
export const leaveTeamAsync = createAsyncThunk(
  'team/Leave',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const currentUser = state.user;
    const currentTeam = state.teams.team;
    await Promise.all([
      leaveTeamByUserId(currentUser.id),
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} left the team`,
        teamId: currentTeam.team_id
      })
    ]);
    thunkAPI.dispatch(getUserAsync(currentUser));
    thunkAPI.dispatch(getTeamAsync(currentUser.id));
    thunkAPI.dispatch(slice.actions.clearTeamFull());
    thunkAPI.dispatch(slice.actions.clearTeam());
    thunkAPI.dispatch(clearList());
  }
);
export const addTeamMemberAsync = createAsyncThunk(
  'team/Invite',
  async ({email, requestPermissions}, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    const teams = thunkAPI.getState().teams;
    const teamId = teams.team.team_id;
    
    var user = await (getUserByEmail(email));
    const userid = uuidv4();

    if(user.user_id === undefined || user.user_id== "") 
    {
       await registerUser({
        first_name: '',
        last_name: '',
        user_id: userid,
        email: email.toLowerCase(),
        avatar: '',
        hourlyRate: 0,
        registerDate: new Date(),
        expireDate: '',
        plan: '',
        premium: 1,
        showOnboarding: 0,
        isGoogleUser: 0,
        activeUser: 0,
        source:'teams:addTeamMemberAsync',
        password:''
      });
      user = await (getUserByEmail(email));
    };
    //axios.defaults.baseURL = process.env.REACT_APP_FEEHIVE_API;
    const l_url = `https://login.feehive.io/register/${teamId}/${email.toLowerCase()}`;

    //const l_url =`http://localhost:4000/register/${teamId}/${email}`;
    const l_title = currentUser.firstName + ' ' + currentUser.lastName;
    const l_emailBody = intviteTeamEmail(l_title, l_url);
    const requestOptions = {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      body: JSON.stringify({ email: email.toLowerCase(), teamId: teamId, emailBody: l_emailBody })
    };

    await Promise.all([
      fetch(process.env.REACT_APP_FEEHIVE_API + 'inviteTeamMember', requestOptions)
        //fetch('http://localhost:4000/.netlify/functions/inviteTeamMember', requestOptions)
        .then(response => response.json())
        .then(data => {
          thunkAPI.dispatch(setSendInvite(true))
          thunkAPI.dispatch(setInviteMessage(data));
        })
        .catch(e => {
          thunkAPI.dispatch(setSendInvite(true));
          thunkAPI.dispatch(setInviteMessage(e));
        }
        ),
      addTeamMember({
        teamId,
        userId: user.user_id,
        permissions: teamMemberPermissions.invite,
        requestPermissions:requestPermissions
      }),
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} invited a user (${email}) to the team`,
        teamId
      })
    ]);
    thunkAPI.dispatch(getTeamFullAsync({teamId : teams.team_id, userId : ""}));
  }
);




export const joinNewTeamAsync = createAsyncThunk(
  'team/Join',
  async ({ teamId, userId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const currentUser = state.user;
    // const teams = thunkAPI.getState().teams;
    // const teamId = teams.team.team_id;

    updateTeamMemberPermissions({
      userId: userId ,
      teamId
    });
    return Promise.all([
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} joined the team`,
        teamId
      })
    ]);
  }
);

export const joinTeamAsync = createAsyncThunk(
  'team/Join',
  async ({ teamId, userId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const currentUser = state.user;

     addTeamMember({
      teamId,
      userId: userId || currentUser.id,
      permissions: teamMemberPermissions.user,
      requestPermissions:""
    });
    return Promise.all([
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} joined the team`,
        teamId
      })
    ]);
  }
);

export const acceptJoinTeamRequestAsync = createAsyncThunk(
  'team/Admin/Accept',
  (userId) => null
);

export const updateTeamMemberAsync = createAsyncThunk(
  'team/Member/Update',
  async ({ userId, ...rest }, thunkAPI) => {
    const { teams } = thunkAPI.getState();
    if (!teams?.team?.team_id) return;
    await updateTeamMember({ userId, teamId: teams.team.team_id, ...rest });
    thunkAPI.dispatch(getTeamFullAsync( {teamId : teams.team.team_id, userId : ""} ));
  }
);

export const updateTeamMemberPermissionsAsync = createAsyncThunk(
  'team/Member/Update',
  async ({ userId, ...rest }, thunkAPI) => {
    const { teams } = thunkAPI.getState();
    if (!teams?.team?.team_id) return;
    await updateTeamMemberPermissions({ userId, teamId: teams.team.team_id, ...rest });
    thunkAPI.dispatch(getTeamFullAsync( {teamId : teams.team.team_id, userId : ""} ));
  }
);

export const removeUserFromTeamAsync = createAsyncThunk(
  'team/Admin/Remove',
  async (userId, thunkAPI) => {
    const { teams } = thunkAPI.getState();
    await deleteUserFromTeam({ userId, teamId: teams.team.team_id });
    thunkAPI.dispatch(getTeamFullAsync({teamId : teams.team.team_id, userId : ""} ));
  }
);
async function fullClients(teamId, userId) {
  // Get all clients within a team
  const clients = await getTeamClientsByTeamId({ teamId, userId });
  // Prepare client ids to find cases
  const { clientKeys, clientIds } = clients.reduce(
    (acc, cur) => {
      acc.clientKeys[cur.client_id] = {
        ...cur,
        cases: {}
      };
      acc.clientIds.push(cur.client_id);
      return acc;
    },
    { clientKeys: {}, clientIds: [] }
  );
  const casesPromises = clientIds.map(() => getTeamCasesById({ userId }));
  const groups = await Promise.all(casesPromises);
  const taskPromises = [];
  groups.forEach((cases) => {
    if (Array.isArray(cases) && cases.length) {
      if (clientKeys[cases[0].client_id]) {
        clientKeys[cases[0].client_id].cases = cases.reduce((acc, cur) => {
          acc[cur.case_id] = {
            ...cur,
            tasks: []
          };
          return acc;
        }, {});
        cases.forEach(({ case_id }) =>
          taskPromises.push(getTasksByCaseId(case_id))
        );
      }
    }
  });
  const groupedTasks = await Promise.all(taskPromises);
  groupedTasks.forEach((tasks) => {
    if (Array.isArray(tasks) && tasks.length) {
      const firstTask = tasks[0];
      const cases = clientKeys[firstTask.task.client_id].cases;
      if (cases[firstTask.task.case_id]) {
        cases[firstTask.task.case_id].tasks = tasks;
      }
    }
  });
  return Object.values(clientKeys).reduce((acc, cur) => {
    cur.cases = Object.values(cur.cases);
    acc.push(cur);
    return acc;
  }, []);
}
export const getTeamClientsAsync = createAsyncThunk(
  'team/Clients/Get',
  async (teamId, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    return fullClients(teamId, currentUser.id);
  }
);

export const getTeamCasesAsync = createAsyncThunk(
  'team/GetTasks',
  async ({ status }, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    return getTeamCasesById({ userId: currentUser.id, status });
  }
);

export const getCustomerDetail = createAsyncThunk(
  'team/GetCustomerDetail',
  async ({ email }, thunkAPI) => {
    // const currentUser = thunkAPI.getState().user;
    // const teamOnwerEmail = await getTeamOwnerEmail({teamOwner});
    var l_customerDetail = await getCustomersDetail(email);
    thunkAPI.dispatch(setCustomerDetail(l_customerDetail));
  }
);

// export const setSubscriptionDetail = createAsyncThunk(
//   'team/setSubscriptionDetail',
//   async ({ id }, thunkAPI) => {

//     const [teamOwner] = await Promise.all([getSubscription(id)]);
//     if (teamOwner) {
//       return;
//     }
  

//     // const data = await Promise.all (getSubscription(id));

//     // <Redirect to="/secure/PaymentPlan" />;

//     thunkAPI.dispatch(setSubscription(values));
//   }
// );
// export const getCaseByCaseID = createAsyncThunk(
//   'team/GetCaseDetail',
//   async ({ caseId }, thunkAPI) => {
//     if(caseId)
//     {
//       const caseDetail = await getCases(caseId);
//       if(caseDetail.length)
//       {
//         var l_caseTeam = await getTeamByTeamId( caseDetail[0].team_id);
//         thunkAPI.dispatch(setCaseOwner(l_caseTeam));
//       }
//     }
//   }
// );

export const shareBudgetNewTeamMemberAsync = createAsyncThunk(
  'team/Share/Budget',
  async ({ caseId, ...values }, thunkAPI) => {
    const { email, budgetPermission, showtaskprice } = values;
    const teams = thunkAPI.getState().teams;
    const teamId = teams.team.team_id;

    const currentcase = thunkAPI.getState().cases.currentCase;
    const client = thunkAPI.getState().cases.currentCase.client;
    const statusColor = statusColorByStatus(currentcase?.status);
    const currentUser = thunkAPI.getState().user;
    const l_userName = currentUser.firstName + ' ' + currentUser.lastName;
    const clientId = thunkAPI.getState().cases.currentCase?.client_id;
   
    var user = await (getUserByEmail(email.toLowerCase()));
    const userid = uuidv4();

    if(user.user_id === undefined || user.user_id === '') 
    {
       await registerUser({
        first_name: '',
        last_name: '',
        user_id: userid,
        email: email.toLowerCase(),
        avatar: '',
        hourlyRate: 0,
        registerDate: new Date(),
        expireDate: '',
        plan: '',
        premium: 1,
        showOnboarding: 0,
        isGoogleUser: 0,
        activeUser: 0,
        source:'teams:shareBudgetNewTeamMemberAsync',
        password:''
      });
      user = await (getUserByEmail(email.toLowerCase()));
    };
    

    const l_url = `https://login.feehive.io/register/${teamId}/${email.toLowerCase()}`;
    var p_buttonText = 'Join Team';
    const l_emailBody = emailBody(l_userName, budgetPermission, currentcase?.fake_case_id, currentcase?.date, client?.company, statusColor, currentcase?.status, caseId, email, l_url, p_buttonText);
    const requestOptions = {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      body: JSON.stringify({ email: email.toLowerCase(), teamId: teamId, emailBody: l_emailBody })
    };

    await Promise.all([

      fetch(process.env.REACT_APP_FEEHIVE_API + 'inviteTeamMember', requestOptions)
        //fetch('http://localhost:4000/.netlify/functions/inviteTeamMember', requestOptions)
        .then(response => response.json())
        .then(data => { })
        .catch(e => { console.log(e) }
      ),

      addTeamMember({
        teamId,
        userId: user.user_id,
        permissions: teamMemberPermissions.invite,
        requestPermissions:budgetPermission
      }),
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} invited a user (${email}) to the team`,
        teamId
      }),
      createCaseActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} invited a user (${email}) to the team`,
        caseId,
        userId: currentUser.id,
        clientId
      }),
      createCasePermission({
        case_id: caseId,
        identity: email,
        permissions: budgetPermission,
        shared_mode: 1,
        show_task_price: showtaskprice || 1,
        shared_by: l_userName
        //show_task_price:"0"
      }),

    ]);
    thunkAPI.dispatch(getTeamFullAsync({teamId : teams.team.team_id, userId : ""} ));
  }
);
export const shareBudgetInternalAsync = createAsyncThunk(
  'team/Share/Budget',
  async ({ caseId, ...values }, thunkAPI) => {
    const { email, emails, budgetPermission, showtaskprice } = values;
   
    const emailsFiltered = emails.filter((i) => i !== email);
    emailsFiltered.push(email.toLowerCase());
    if (!emailsFiltered.length) return;
    const users = await Promise.all(
      emailsFiltered.map((e) => getUserByEmail(e))
    );
    const currentcase = thunkAPI.getState().cases.currentCase;
    const client = thunkAPI.getState().cases.currentCase.client;
    const statusColor = statusColorByStatus(currentcase?.status);
    const currentUser = thunkAPI.getState().user;
    const l_userName = currentUser.firstName + ' ' + currentUser.lastName;
    const teams = thunkAPI.getState().teams;
    const teamId = teams.team.team_id;
    const clientId = thunkAPI.getState().cases.currentCase?.client_id;
    return Promise.all([
      ...users.map(({ user_id }) =>
        createCasePermission({
          case_id: caseId,
          identity: user_id,
          permissions: budgetPermission,
          shared_mode: 1,
          show_task_price: showtaskprice,
          shared_by: l_userName
          //show_task_price:"0"
        })
      ),
      ...users.map((i) => {
        var type = "I";
        // var l_url =`https://login.feehive.io/shared/type/case/${caseId}/identity/${i.user_id}`;
        var l_url = `https://login.feehive.io/secure/${caseId}/case`;
        var p_buttonText = 'Open Budget';
        const l_emailBody = emailBody(l_userName, budgetPermission, currentcase?.fake_case_id, currentcase?.date, client?.company, statusColor, currentcase?.status, caseId, i.user_id, l_url, p_buttonText);
        const requestOptions = {
          method: 'POST',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
          body: JSON.stringify({
            emails: [{ email: i.email }],
            emailSubject: values.emailSubject,
            emailBody: l_emailBody
          })
        };
        createTeamActivity({
          message: `${currentUser.firstName} ${currentUser.lastName} shared a budget with  ${i.first_name} ${i.last_name} (${i.email})`,
          teamId
        });

        createCaseActivity({
          message: `${currentUser.firstName} ${currentUser.lastName} shared a budget with ${i.first_name} ${i.last_name} (${i.email})`,
          caseId,
          userId: currentUser.id,
          clientId
        });
        return fetch(process.env.REACT_APP_FEEHIVE_API + 'shareBudgetInternal', requestOptions)
          .then(response => response.json())
          .then(data => console.log(data));
      })
    ]);
  }
);
function statusColorByStatus(p_status) {
  const colors = {
    all: {
      bg: '#e8e8e8'
    },
    complete: {
      bg: '#569171'
    },
    progress: {
      bg: '#EEE4C6'
    },
    draft: {
      bg: '#2A3F58'
    },
    approved: {
      bg: '#7298B4'
    },
    sent: {
      bg: '#FCC95A'
    }
  };
  return colors[p_status]?.bg
};

export const shareBudgetExternalAsync = createAsyncThunk(
  'team/Share/BudgetExternal',
  async ({ caseId, identity, ...values }, thunkAPI) => {
    const { email, emails, budgetPermission, showtaskprice } = values;
   
    const currentcase = thunkAPI.getState().cases.currentCase;
    const client = thunkAPI.getState().cases.currentCase.client;
    const total = [...new Set([email, ...emails])];
    const statusColor = statusColorByStatus(currentcase?.status);
    const currentUser = thunkAPI.getState().user;
    const l_userName = currentUser.firstName + ' ' + currentUser.lastName;
    const teams = thunkAPI.getState().teams;
    const teamId = teams.team.team_id;
    const clientId = thunkAPI.getState().cases.currentCase?.client_id;
    if (!total.length) return;
    return Promise.all([
      ...total.map((i) =>
        createCasePermission({
          case_id: caseId,
          identity: i,
          permissions: budgetPermission,
          shared_mode: 2,
          show_task_price: showtaskprice,
          shared_by: l_userName
          //show_task_price:"0"
        }),
      ),
      ...total.map((i) => {
        var l_url = `https://login.feehive.io/shared/case/${caseId}/identity/${i.toLowerCase()}`;
        var p_buttonText = 'Open Budget';
        const l_emailBody = emailBody(l_userName, budgetPermission, currentcase?.fake_case_id, currentcase?.date, client?.company, statusColor, currentcase?.status, caseId, i, l_url,p_buttonText);

        const requestOptions = {
          method: 'POST',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
          body: JSON.stringify({
            emails: [{ email: i }],
            emailSubject: values.emailSubject,
            emailBody: l_emailBody
          })
        };
           createTeamActivity({
            message: `${currentUser.firstName} ${currentUser.lastName} shared a budget with the client (${i})`,
            teamId
          });

          createCaseActivity({
            message: `${currentUser.firstName} ${currentUser.lastName} shared a budget with the client (${i})`,
            caseId,
            userId: currentUser.id,
            clientId
          });
          return fetch(process.env.REACT_APP_FEEHIVE_API + 'shareBudgetInternal', requestOptions)
          .then(response => response.json())
          .then(data => console.log(data));
          
      })
    ]);
  }
);

export const getTeamFullAsync = createAsyncThunk(
  'team/GetFull',
  async ({teamId,userId}, thunkAPI) => {
    
    const currentUser = thunkAPI.getState().user;
    const l_UserID = (userId == null || userId == "")  ? currentUser.id  : userId;
    const [alerts, payment, members, clients, cases] = await Promise.all([
      getTeamAlertsById(teamId),
      getTeamPaymentById(teamId),
      getTeamMembersById(teamId),
      getTeamClientsByTeamId({ teamId, userId: l_UserID}),
      getTeamCasesById({ userId: l_UserID})
    ]);
    const { keys, proms } = members.reduce(
      (acc, cur) => {
        if (!cur.user_id) return acc;
        acc.keys[cur.user_id] = {
          permissions: cur.permissions
        };
        acc.proms.push(getUserByUserId(cur.user_id));
        return acc;
      },
      {
        keys: {},
        proms: []
      }
    );
    const memberUsers = await Promise.all(proms);
    memberUsers.forEach((user) => {
      if (user.user_id) keys[user.user_id].user = user;
    });
    return {
      alerts: alerts.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      ),
      payment,
      members: Object.values(keys),
      clients,
      cases
    };
  }
);

export const getClientsAsync = createAsyncThunk(
  'team/Client/Get',
  async (teamId, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    return getTeamClientsByTeamId({ teamId, userId: currentUser.id });
  }
);

export const deleteClientAsync = createAsyncThunk(
  'team/Client/Delete',
  async ({ clientId, teamId }, thunkAPI) => {
    await deleteClient(clientId);
    // thunkAPI.dispatch(getTeamClientsAsync(teamId));
    thunkAPI.dispatch(getTeamFullAsync({teamId : teamId, userId : ""}));
  }
);

export const updateClientAsync = createAsyncThunk(
  'team/Client/Update',
  async ({ clientId, teamId, ...rest }, thunkAPI) => {
    await updateClient({ clientId, ...rest });
    // thunkAPI.dispatch(getTeamClientsAsync(teamId));
    thunkAPI.dispatch(getTeamFullAsync({teamId : teamId, userId : ""} ));
  }
);

export const createClientAsync = createAsyncThunk(
  'team/Client/Create',
  async ({ teamId, ...rest }, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    await Promise.all([
      createClient({ teamId, userId: currentUser.id, ...rest }),
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} created a new client`,
        teamId
      })
    ]);
    thunkAPI.dispatch(getTeamFullAsync({teamId : teamId, userId : ""}));
  }
);

export const createClientHeaderAsync = createAsyncThunk(
  'team/Client/CreateHeader',
  async ({ teamId, ...rest }, thunkAPI) => {
    const currentUser = thunkAPI.getState().user;
    const [clientId] = await Promise.all([
      createClient({ teamId, userId: currentUser.id, ...rest }),
      createTeamActivity({
        message: `${currentUser.firstName} ${currentUser.lastName} created a new client`,
        teamId
      })
    ]);
    const clients = await getTeamClientsByTeamId({
      teamId,
      userId: currentUser.id
    });
    return {
      clients,
      clientId
    };
  }
);

const slice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    sortByCases(state, action) {
      state.teamFull.cases = action.payload;
    },
    createClientReset(state) {
      state.teamClientCreateLoadable = Loadable().createDefaultLoadable();
    },
    sortTeamClients(state, action) {
      state.teamFull.clients = action.payload;
    },
    clearTeam(state) {
      state.team = {};
    },
    setSendInvite(state, action) {
      state.sendInvite = action.payload;
    },
    setInviteMessage(state, action) {
      state.inviteMessage = action.payload;
    },
    setSharedBudegetUsername(state, action) {
      state.sharedBudegetUsername = action.payload;
    },
    setCustomerID(state, action) {
      state.customerID = action.payload;
    },
    setCustomerDetail(state, action){
      state.customerDetail=action.payload;
    },
    // setSubscription(state, action){
    //   state.subscriptionDetail=action.payload;
    // },
    // setCaseOwner(state, action){
    //   state.caseOwner=action.payload;
    // },
    clearTeamFull(state) {
      state.teamFull = {
        clients: [],
        alerts: []
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamAsync.pending, (state) => {
        state.teamLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getTeamAsync.fulfilled, (state, action) => {
        state.teamLoadable = Loadable().createSuccessLoadable();
        state.team = action.payload;
      })
      .addCase(getTeamFullAsync.pending, (state) => {
        state.teamFullLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getTeamFullAsync.fulfilled, (state, action) => {
        state.teamFullLoadable = Loadable().createSuccessLoadable();
        state.teamFull = action.payload;
      })
      .addCase(createTeamAsync.pending, (state) => {
        state.teamCreateLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(createTeamAsync.fulfilled, (state) => {
        state.teamCreateLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(createTeamAsync.rejected, (state) => {
        state.teamCreateLoadable = Loadable().createErrorLoadable(
          'You are already a team owner of another team'
        );
      })
      .addCase(getTeamsAsync.pending, (state) => {
        state.listLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getTeamsAsync.fulfilled, (state, action) => {
        state.listLoadable = Loadable().createSuccessLoadable();
        state.list = action.payload;
      })
      .addCase(joinTeamAsync.pending, (state) => {
        state.joinLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(joinTeamAsync.fulfilled, (state, action) => {
        state.joinLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(joinTeamAsync.rejected, (state, action) => {
        state.joinLoadable = Loadable().createErrorLoadable(
          'You are already a team owner or a member of another team'
        );
      })
      .addCase(createClientAsync.pending, (state) => {
        state.teamClientCreateLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(createClientAsync.fulfilled, (state, action) => {
        state.teamClientCreateLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(updateTeamNameAsync.pending, (state) => {
        state.teamNameUpdateLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(updateTeamNameAsync.fulfilled, (state, action) => {
        state.teamNameUpdateLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(leaveTeamAsync.pending, (state) => {
        state.leaveTeamLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(leaveTeamAsync.fulfilled, (state, action) => {
        state.leaveTeamLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(getTeamClientsAsync.pending, (state) => {
        state.teamClientsLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getTeamClientsAsync.fulfilled, (state, action) => {
        state.teamClientsLoadable = Loadable().createSuccessLoadable();
        state.teamFull.clients = action.payload;
      })
      .addCase(acceptJoinTeamRequestAsync.pending, (state) => {
        state.teamAcceptMemberLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(acceptJoinTeamRequestAsync.fulfilled, (state, action) => {
        state.teamAcceptMemberLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(removeUserFromTeamAsync.pending, (state) => {
        state.teamRemoveMemberLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(removeUserFromTeamAsync.fulfilled, (state, action) => {
        state.teamRemoveMemberLoadable = Loadable().createSuccessLoadable();
      })
      .addCase(getClientsAsync.pending, (state) => {
        state.teamClientsLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(getClientsAsync.fulfilled, (state, action) => {
        state.teamClientsLoadable = Loadable().createSuccessLoadable();
        state.teamFull.clients = action.payload;
      })
      .addCase(getTeamCasesAsync.fulfilled, (state, action) => {
        state.teamFull.cases = action.payload;
      })
      .addCase(createClientHeaderAsync.pending, (state) => {
        state.createClientInCaseLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(createClientHeaderAsync.fulfilled, (state, action) => {
        state.createClientInCaseLoadable = Loadable().createSuccessLoadable();
        state.teamFull.clients = action.payload.clients;
        state.selectedClientId = action.payload.clientId;
      });
  }
});

export const { reducer } = slice;
export const { sortTeamClients, sortByCases, createClientReset, setSendInvite, setInviteMessage, setSharedBudegetUsername,setCustomerID,setCustomerDetail } =
  slice.actions;

export default slice;
