import { combineReducers } from '@reduxjs/toolkit';
import { reducer as cases } from 'src/slices/cases';
import { reducer as teams } from 'src/slices/teams';
import { reducer as tasks } from 'src/slices/tasks';
import { reducer as user } from 'src/slices/user';
import { reducer as app } from 'src/slices/app';

const rootReducer = combineReducers({
  cases,
  teams,
  tasks,
  user,
  app
});

export default rootReducer;
