import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise=null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      // "pk_test_51K7MpDJRUmGJhIP2K8mzJZoc2axGKC9fOp1kVE1TeenUTBWzr6LEEUBxUN7VM14pgSXYOgUH5waVNNw4sKCih6uP00DBC4wUv6"
      'pk_live_51K7MpDJRUmGJhIP2I4pf57tyAB2c0anQy931N6YA271oKkFJSrNMfXgHX4cIKAsjxakXgONiYP0Jmz0cFahRpiHp00O2wfi0RM'
          // 'pk_test_51K7MpDJRUmGJhIP2K8mzJZoc2axGKC9fOp1kVE1TeenUTBWzr6LEEUBxUN7VM14pgSXYOgUH5waVNNw4sKCih6uP00DBC4wUv6'
    );
  }
  return stripePromise;
};
export default initializeStripe;