import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Loadable from 'src/utils/loadable';
import {
  createTask,
  updateTask,
  deleteTask,
  copyTask,
  getCaseCategoriesByCaseId,
  createCaseCategory,
  taskSwap,
  categorySwap,
  destinationTaskSwap,
  getCategoryMaxSequenceNo,
  getTaskMaxSequenceNo,
  createCategory
} from 'src/network';
import {
  getCategories,
} from 'src/utils/events';

const initialState = {
  list: [],
  triggerFetch: false,
  loadable: Loadable().createDefaultLoadable(),
  updateTaskLoadable: Loadable().createDefaultLoadable(),
  createCategoryLoadable: Loadable().createDefaultLoadable(),
  taskData: [],
  newDatalist:[],
  deleteTaskList: [],
};

export const getCaseCategoriesByCaseIdAsync = createAsyncThunk(
  'tasks/Categories/Get',
  async (caseId) => getCaseCategoriesByCaseId(caseId)
);

export const createCaseCategoryAsync = createAsyncThunk(
  'tasks/Categories/Create',
  async ({ title, case_id }, thunkAPI) => {
    await createCaseCategory({ title, case_id });
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(case_id));
  }
);
export const createCaseCategoryFirstTimeAsync = createAsyncThunk(
  'tasks/Categories/Create',
  async ({ title, case_id }, thunkAPI) => {
    const user = thunkAPI.getState().user;
    const currentUser = thunkAPI.getState();
    const currentCase = thunkAPI.getState().cases.currentCase;
    const categoryId = await createCaseCategory({ title, case_id });

    var l_newCreateCategories=getCategories(title);

    //var l_category_sequence_no = await getCategoryMaxSequenceNo({case_id});
    for(var i=0;i<l_newCreateCategories.length;i++)
    {
      // thunkAPI.dispatch(
        await createCategory({
          caseId: case_id,
          clientId: '',
          position: 0,
          hours: 0,
          rate: user.hourlyRate || 1,
          categoryId,
          parentTaskId: '',
          //title: 'New category',
          title:l_newCreateCategories[i],
          category_sequence_no:i ,
          assignedUser:user.id
        })
      //);
    }
    
    
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(case_id));
  }
);

export const createCaseTaskAsync = createAsyncThunk(
  'tasks/Create',
  async (
    {
      categoryId,
      parentTaskId,
      assignedUser,
      caseId,
      clientId,
      hours,
      rate,
      ...rest
    },
    thunkAPI
  ) => {
    const currentUser = thunkAPI.getState();
    
   
    if(parentTaskId)
    {
      var l_task_sequence_no = await getTaskMaxSequenceNo({caseId,parentTaskId,categoryId});
    }
    else
    {
      l_task_sequence_no = 0;
      if(rest.category_sequence_no !=1)
      {
        var l_category_sequence_no = await getCategoryMaxSequenceNo({case_id:caseId});  
      }
      
    };
   
    rest = {...rest,category_sequence_no:l_category_sequence_no || 0,task_sequence_no:l_task_sequence_no || 0 };
    await createTask({
      categoryId,
      caseId,
      clientId,
      parentTaskId,
      hours,
      rate,
      assignedUser: assignedUser ? assignedUser : currentUser.id,
      task_sequence_no:l_task_sequence_no || 0,
      category_sequence_no:l_category_sequence_no || 0,
      ...rest
    });
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(caseId));
  }
);

export const deleteTaskAsync = createAsyncThunk(
  'tasks/Delete',
  async ({ taskId, caseId }, thunkAPI) => {
    await deleteTask(taskId);
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(caseId));
  }
);

export const copyTaskAsync = createAsyncThunk(
  'tasks/Copy',
  async ({ taskId, caseId }, thunkAPI) => {
    await copyTask({taskId, caseId});
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(caseId));
  }
);

export const updateTaskByIdAsync = createAsyncThunk(
  'tasks/Update',
  async ({ caseId, taskId, ...values },  thunkAPI) => {
    await updateTask({ taskId, ...values },thunkAPI);
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(caseId));
    // return updateTask({ taskId, ...values },thunkAPI);
  }
);

export const taskSwapByIdAsync = createAsyncThunk(
  'tasks/Update',
  async ({ caseId,parentTaskID, sourceTaskId,destinatioTaskId }, thunkAPI) => {
    await taskSwap({caseId,parentTaskID, sourceTaskId,destinatioTaskId });
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(caseId));
  }
);

export const categorySwapByIdAsync = createAsyncThunk(
  'tasks/Update',
  async ({ caseId,parentTaskID, sourceTaskId,destinatioTaskId }, thunkAPI) => {
    await categorySwap({caseId,parentTaskID, sourceTaskId,destinatioTaskId });
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(caseId));
  }
);

export const destinationTaskSwapByIdAsync = createAsyncThunk(
  'tasks/Update',
  async ({ caseId, taskId, values }, thunkAPI) => {
    await destinationTaskSwap({taskId, values });
    thunkAPI.dispatch(getCaseCategoriesByCaseIdAsync(caseId));
  }
);

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    waitForTasksToUpdate(state) {
      state.loadable = Loadable().createLoadingLoadable();
      state.triggerFetch = true;
    },
    resetTriggerFetch(state) {
      state.triggerFetch = false;
    },
    setTaskDataForUpdate(state, action) {
      state.list = action.payload;
    },
    setDeletedTaskList(state, action) {
      state.deleteTaskList= action.payload;
    },
    setNewDataList(state, action) {
      state.newDatalist = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCaseCategoriesByCaseIdAsync.pending, (state) => {
        state.loadable = Loadable().createLoadingLoadable();
      })
      .addCase(getCaseCategoriesByCaseIdAsync.fulfilled, (state, action) => {
        state.loadable = Loadable().createSuccessLoadable();
        state.list = action.payload;
      })
      .addCase(createCaseCategoryAsync.pending, (state) => {
        state.createCategoryLoadable = Loadable().createLoadingLoadable();
      })
      .addCase(createCaseCategoryAsync.fulfilled, (state) => {
        state.createCategoryLoadable = Loadable().createSuccessLoadable();
      });
  }
});

export const { reducer } = slice;
export const { waitForTasksToUpdate, resetTriggerFetch, setTaskDataForUpdate,setNewDataList,setDeletedTaskList } =
  slice.actions;
export default slice;
