import {
  experimentalStyled,
  Box,
  Container,
  Grid,
  Button,
  Link,
  FormHelperText
} from '@material-ui/core';
import { Formik } from 'formik';
import { Link as RouterLink,useParams } from 'react-router-dom';
import AuthBackground from 'src/components/Authenticated/AuthBackground';
import Input from 'src/components/Input';
import { ReactComponent as LogoIcon } from 'src/logo.svg';
import { ReactComponent as ChevronRightIcon } from 'src/assets/svg/ChevronCircle.svg';
import useAuth from 'src/hooks/useAuth';
import { useState } from 'react';
import './style.css';
import validator from 'validator';

function Login() {
  const { signInWithGoogle, signInWithEmailAndPassword } = useAuth();
  const [errorMessage, setMessage] = useState('');
  const { teamId,email } = useParams();

  const handleGoogleClick = async () => {
    setMessage('');
    try {
      await signInWithGoogle();
    } catch (error) {
      setMessage(error.message);
    }
  };

  const handleOnSubmit = async (values) => {
    setMessage('');
    if (!values.email) {
      setMessage('Required!');
      return;
    } else if (!values.password) {
      setMessage('Required!');
      return;
    };

    if (validator.isEmail(values.email)) {
      signInWithEmailAndPassword(values.email, values.password).catch((error) =>
        setMessage(error.message)
      );
    } else {
      setMessage('Please enter valid email address');
      return;
     }

  };

  return (
    <AuthBackground >
      <Container sx={{
        height: '100vh',

        fontFamily: 'MaisonNeue-Book',
        root: { fontFamily: 'MaisonNeue-Book' }

      }}>
        <Grid className='login-form'
          container
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Grid item xs={12} md={12} lg={7}>
            <LoginContainer>
              <Box className='logo' alignItems="flex-start">
                <Link component={RouterLink} to="/">
                  <LogoIcon />
                </Link>
              </Box>
              <Formik
                onSubmit={handleOnSubmit}
                initialValues={{ email: '', password: '' }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Box className='filed-design' display="flex" alignItems="center" gap={2}>
                    <Input
                      value={values.email}
                      onChange={handleChange('email')}
                      placeholder="Email address"
                    />
                    <Input
                      value={values.password}
                      onChange={handleChange('password')}
                      placeholder="Password"
                      type="password"
                    />
                    <LoginButton className='button-design' onClick={handleSubmit} type="button">
                      <ChevronRightIcon />
                    </LoginButton>
                  </Box>
                )}
              </Formik>
              {errorMessage ? (
                <Box>
                  <FormHelperText sx={{ color: '#ff0000' }}>{errorMessage}</FormHelperText>
                </Box>
              ) : null}
              <Box display="flex" alignItems="center" className='login-links' gap={3}>
              {email != undefined?
                <LoginLinks to={`/register/${teamId}/${email}`}>Sign Up</LoginLinks>
                :<LoginLinks to={`/register`}>Sign Up</LoginLinks>}
                {email != undefined?
                <LoginLinks to={`/recover-password/${teamId}/${email}`}>Forgot password?</LoginLinks>
                :<LoginLinks to={`/recover-password`}>Forgot password?</LoginLinks>}
              </Box>
            </LoginContainer>
            <LoginContainer mt={3}>
              <GoogleButton
                fullWidth
                onClick={handleGoogleClick}
                size="large"
                variant="outlined"
              >
                <GoogleIcon alt="Google" src="/static/images/logo/google.svg" />
                Sign in with Google
              </GoogleButton>
            </LoginContainer>
          </Grid>
        </Grid>
      </Container>
    </AuthBackground>
  );
}

export default Login;

export const LoginContainer = experimentalStyled(Box)(
  ({ theme }) => `
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding: ${theme.spacing(4)};
  display: flex;
  flex-flow: column;
  gap: ${theme.spacing(3)}
`
);

export const LoginButton = experimentalStyled(Button)(
  () => `
  padding: 0;
  margin: 0;
  border-radius: 50%;
  
  width: 64px;
  height: 64px;
`
);

export const LoginLinks = experimentalStyled(RouterLink)(
  () => `
  font-size: 12px;
  line-height: 12px;
  color: #111;
  text-decoration: none;
  &:hover {
    color: #838383;
  }
`
);

export const GoogleIcon = experimentalStyled('img')(
  ({ theme }) => `
  margin-right: ${theme.spacing(1)}
`
);

export const GoogleButton = experimentalStyled(Button)(
  ({ theme }) => `
  border-color: ${theme.palette.greyLight};
  color: ${theme.palette.textLink};
  text-transform: capitalize;
  &:hover {
    span {
      color: ${theme.palette.textLinkHover};
    }
    background: rgba(247, 231, 214, 0.5);
    border-color: ${theme.palette.yellow};
  }
`
);
