import { experimentalStyled, InputBase } from '@material-ui/core';

function Input(props) {
  return <InputFeeHive {...props} />;
}

export default Input;

const InputFeeHive = experimentalStyled(InputBase)(
  ({ theme }) => `
  background: #FFFFFF;
  opacity: 0.8;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  box-sizing: border-box;
  width: 100%;
  color: #111;
  font-size: 16px;
  
  border: 1px solid #E9E9E9;
  box-sizing: border-box;
  border-radius: 1px;
`
);
