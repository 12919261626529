import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { useEffect } from 'react';
import { getUserAsync } from 'src/slices/user';
import { getTeamAsync } from 'src/slices/teams';
import { useDispatch } from 'react-redux';

const Authenticated = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAsync(user));
    dispatch(getTeamAsync(user.id));
  }, []);

  if (!isAuthenticated) return <Redirect to="/login" />;
  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
