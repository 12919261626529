import { createMuiTheme,makeStyles } from '@material-ui/core/styles';
import FoundersGroteskMedium from 'src/fonts/FoundersGrotesk-Medium.woff2';
import FoundersGroteskMediumItalic from 'src/fonts/FoundersGrotesk-MediumItalic.woff2';
import FoundersGroteskRegular from 'src/fonts/FoundersGrotesk-Regular.woff2';
import FoundersGroteskRegularItalic from 'src/fonts/FoundersGrotesk-RegularItalic.woff2';
import MaisonNeueBold from 'src/fonts/MaisonNeue-Bold.woff2';
import MaisonNeueBoldItalic from 'src/fonts/MaisonNeue-BoldItalic.woff2';
import MaisonNeueBook from 'src/fonts/MaisonNeue-Book.woff2';
import MaisonNeueBookItalic from 'src/fonts/MaisonNeue-BookItalic.woff2';
import MaisonNeueMediumItalic from 'src/fonts/MaisonNeue-MediumItalic.woff2';
import MaisonNeueMedium from 'src/fonts/MaisonNeue-Medium.woff2';

const theme = createMuiTheme({

  typography: {
    fontFamily: 'MaisonNeue-Book !important',
  },
  
  components: {
    MuiCssBaseline: {
      
      styleOverrides: `
        @font-face {
          font-family: 'FoundersGrotesk-Regular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('FoundersGrotesk-Regular'), url(${FoundersGroteskRegular}) format('woff2');
        }
        @font-face {
          font-family: 'FoundersGrotesk-RegularItalic';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('FoundersGrotesk-RegularItalic'), url(${FoundersGroteskRegularItalic}) format('woff2');
        }
        @font-face {
          font-family: 'FoundersGrotesk-Medium';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('FoundersGrotesk-Medium'), url(${FoundersGroteskMedium}) format('woff2');
        }
        @font-face {
          font-family: 'FoundersGrotesk-MediumItalic';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('FoundersGrotesk-MediumItalic'), url(${FoundersGroteskMediumItalic}) format('woff2');
        }
        @font-face {
          font-family: 'MaisonNeue-Bold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('MaisonNeue-Bold'), url(${MaisonNeueBold}) format('woff2');
        }
        @font-face {
          font-family: 'MaisonNeue-BoldItalic';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('MaisonNeue-BoldItalic'), url(${MaisonNeueBoldItalic}) format('woff2');
        }
        @font-face {
          font-family: 'MaisonNeue-Book';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('MaisonNeue-Book'), url(${MaisonNeueBook}) format('woff2');
        }
        @font-face {
          font-family: 'MaisonNeue-BookItalic';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('MaisonNeue-BookItalic'), url(${MaisonNeueBookItalic}) format('woff2');
        }
        @font-face {
          font-family: 'MaisonNeue-Medium';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('MaisonNeue-Medium'), url(${MaisonNeueMedium}) format('woff2');
        }
        @font-face {
          font-family: 'MaisonNeue-MediumItalic';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('MaisonNeue-MediumItalic'), url(${MaisonNeueMediumItalic}) format('woff2');
        }
     } 
      `
    },
  },
 
  palette: {
    orange: '#FCC95A',
    yellow: '#F6D280',
    yellowLight: '#EEE4C6',
    secureBackground: '#f8f8f6',
    lightGreen: '#569171',
    grey: '#2A3F58',
    greyLight: '#E9E9E9',
    greyCost: '#444444',
    blueMuted: '#7298B4',
    textLink: '#838383',
    textLinkHover: '#111111',
    white: '#FFFFFF'
  },
});

export default theme;
