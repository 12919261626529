import React from 'react';
// import ReactGA from 'react-ga';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import store from 'src/store';
import { AuthProvider } from './contexts/FirebaseAuthContext';
import routes, { renderRoutes } from './router';
import ScrollToTop from './utils/ScrollToTop';
import theme from './themes/FeeHiveLight';

import './fonts/index.css';

import classes from './themes/FeeHiveStyles';


function App() {
   const style = classes();
  return (
    <ReduxProvider store={store}>
      
      <ThemeProvider theme={theme}>
      <div  className={style.root}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            
          >
            
            <Router forceRefresh>
              <ScrollToTop />
              <CssBaseline />
              <AuthProvider>
                {renderRoutes(routes)}
                </AuthProvider>
            </Router>
          </SnackbarProvider>
        </LocalizationProvider>
        </div>
      </ThemeProvider>
      
    </ReduxProvider>
  );
}

export default App;
