import React, { useState,useMemo } from 'react';
import { parseISO } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, experimentalStyled, Typography, Grid } from '@material-ui/core';
import { selectTeamAlerts, selectTeamCases } from 'src/selectors/teams';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as CircleRegular } from 'src/assets/svg/circle-regular.svg';
function HeaderLinks() {
  const alerts = useSelector(selectTeamAlerts);
  const [isAlertsOpen, setAlertsOpen] = useState(false);
  const cases = useSelector(selectTeamCases);
  // const [isOpenSharedBudget, setOpenSharedBudget] = useState(false);

  const casesFiltered = useMemo(() => {
    var l_cases = [];
    if (cases.length) {
      l_cases = cases.filter((_case) => _case.shared_mode === 1 || _case.shared_mode === 2);
      return [...new Map(l_cases.map(item =>
        [item["case_id"], item])).values()];
    }
    return [];
  }, [cases]);

  return (
    <Box
      gap="8px"
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
    >
      <LinkItems to="/secure/budgets">
        <Grid container gap={2} style={{ alignItems: "center", }}>
          <CircleRegular
            color='#C4C4C4'
            fontSize='16px'
            style={{ alignItems: "center", justifyContent: "center" }}
          />
          <span style={{ fontFamily: "FoundersGrotesk-Regular", fontSize: '16px' }}>Budgets</span>
        </Grid>
      </LinkItems>
      {/* {
        isOpenSharedBudget ? */}
      {
        casesFiltered.length ?
          <LinkItems to="/secure/SharedBudgets" style={{ marginLeft: "10%", width: "90%", height: "48px" }}>
            <Grid container gap={2} style={{ alignItems: "center", }}>
              <span style={{ fontFamily: "FoundersGrotesk-Regular", fontSize: '16px' }}>Shared with you</span>
            </Grid>

          </LinkItems>
          : null
      }

      {/* : null
      } */}
      <LinkItems to="/secure/clients">
        <Grid container gap={2} style={{ alignItems: "center", }}>
          <CircleRegular color='#C4C4C4' fontSize='16px' /><span style={{ fontFamily: "FoundersGrotesk-Regular", fontSize: '16px' }}>Clients</span>
        </Grid>
      </LinkItems>
      <Box className='alert-tab' overflow="auto" display="flex" flexDirection="column">
        <Container
          onClick={() => {
            setAlertsOpen((prev) => {
              if (alerts.length) {
                return !prev;
              }
              return prev;
            });
          }}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid container gap={2} style={{ alignItems: "center", }}>
            <CircleRegular color='#C4C4C4' fontSize='16px' cursor='pointer' /> <span style={{ fontFamily: "FoundersGrotesk-Regular", fontSize: '16px', cursor: 'pointer' }}>Alerts</span>
          </Grid>
          {alerts.length ? (
            <Box className='alert-label'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                fontSize: '12px',
                color: '#FFF',
                background: '#D67C29',
                borderRadius: '100%',
                flexShrink: 0,
                fontWeight: 'bold'
              }}
            >
              {alerts.length}
            </Box>
          ) : null}
        </Container>
        {isAlertsOpen && alerts.length ? (
          <Container
            sx={{
              marginTop: '-5px',
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              overflow: 'auto'
            }}
          >
            {alerts.map((alert, idx) => {
              const timeDisplacement = formatDistanceToNow(
                parseISO(alert.date)
              );
              return (
                <AlertItem
                  key={alert.activity_id}
                  sx={
                    idx === 0
                      ? alerts.length === 1
                        ? { padding: 0, border: 'none' }
                        : { borderTop: 'none', paddingTop: 0 }
                      : {}
                  }
                >
                  <AlertDate>{timeDisplacement}</AlertDate>
                  <AlertMsg>{alert.message}</AlertMsg>
                </AlertItem>
              );
            })}
          </Container>
        ) : null}
      </Box>
    </Box>
  );
}

export default HeaderLinks;

export const LinkItems = experimentalStyled(Link)(({ theme }) => ({
  color: '#404040',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
  borderRadius: '10px',
  padding: theme.spacing(2),
  fontSize: theme.spacing(2),
  background: theme.palette.white,
  textDecoration: 'none',
  width: '100%',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.textLinkHover
  },
  '&:onclick': {
    color: theme.palette.textLinkHover
  },
}));



export const Container = experimentalStyled(Box)(({ theme }) => ({
  color: '#404040',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
  borderRadius: '10px',
  padding: theme.spacing(2),
  fontSize: theme.spacing(2),
  background: theme.palette.white,
  textDecoration: 'none',
  width: '100%'
}));

export const AlertMsg = experimentalStyled(Typography)(() => ({
  color: '#929292',
  fontSize: '12px'
}));
export const AlertDate = experimentalStyled(Typography)(() => ({
  color: '#929292',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '10px'
}));
export const AlertItem = experimentalStyled('div')(() => ({
  padding: '14px 0',
  borderBottom: '1px solid #e0e0e0',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  '&:last-child': {
    borderBottom: 'none',
    paddingBottom: 0
  }
}));
