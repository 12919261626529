import Logo from 'src/assets/image/logo.png';
import { format } from 'date-fns';

export function intviteTeamEmail(p_title, p_url) {
    return `<html lang="en">
            <head>
                <meta name="viewport" content="width=device-width" />
                <title>Newsletter</title>
                <meta charset="utf-8">
                <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport">
            </head>
            <body style="margin:0; padding:0;">

            <div style="max-width:600px;margin:0 auto; font-family:Arial, Helvetica, sans-serif;">
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                        <td><img src="http://cdn.mcauto-images-production.sendgrid.net/f9af07cfef7c4b88/42bcfea7-85f6-4bf6-a3e7-4f3912cd7202/102x32.png" alt="" /></td>
                    </tr>
                    <tr>
                        <td>
                            <p style="font-size:18px; font-weight:bold; margin-bottom:0; margin-top:50px;">${p_title} invited to you his FeeHive team</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label style="color: #929292; font-size:16px; margin-top:20px;display: block;" >Feehive is built for collaboration among lawyers. Share institutional pricing knowledge about every type of client and matter.<br></label>
                            <span>&nbsp;</span>
                            <label style="color: #929292; font-size:16px;display: block;">Feehive to build fee estimates that your clients will understand and trust. Win new business and strengthen existing relationships by aligning with clients on the amount and timing of fees before an engagement begins. Deliver the predictable fees your clients want.<br><br></label>
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            <a href=${p_url} style="margin-top: 50px; display: block; background: #F6D280; border: 1px solid #F6D280; box-sizing: border-box; border-radius: 5px; font-size: 18px;text-decoration:none; color: #404040; padding-left:20px;
                              padding-right:20px; padding-top:20px; padding-bottom:20px; text-align:center; font-weight:bold;">Join Team</a>
                        </td>
                    </tr>
                </table>
            </div>
        </body>
        </html>`
}